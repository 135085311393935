<template>
  <div class="block_wrap block_wh famous_people"
       v-if="dataInfo && dataInfo.quoteList && dataInfo.quoteList.length > 0">
    <container>
      <div class="swiper_wrapper">
        <h2 class="famous_people__title div_static_title" v-if="dataInfo.title">{{dataInfo.title.value}}</h2>
        <swiper
            :navigation="true"
            :modules="modules"
            class="str_vertical"
            :direction="'horizontal'"
            :breakpoints="{800: {
            direction: 'vertical'
          }}"
            :loop="true"
        >
          <swiper-slide
              v-for="item in dataInfo.quoteList"
              :key="item"
          >
            <div class="famous_people_el  block_min">
              <div class="dt1" v-if="item.image1"><img :src="getImage(item.image1, 'hd')" alt=""></div>
              <div class="dt2">
                <h2 class="famous_people__title invisible" v-if="dataInfo.title">{{dataInfo.title.value}}</h2>
                <div class="div_text" v-if="item.text" v-html="item.text.value"></div>
                <div class="div_flex">
                  <div class="div_name fs3">
                    <template v-if="item.name">{{ item.name.value }}&nbsp;</template>
                    <template v-if="item.middleName">{{ item.middleName.value }}</template>
                    <br>
                    <template v-if="item.lastName">{{ item.lastName.value }}</template>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </container>
  </div>
</template>

<script>

import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation} from "swiper";

export default {
  name: "famousPeopleV2",
  components: {Swiper, SwiperSlide},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  setup() {
    return {
      modules: [Navigation]
    }
  },
}
</script>

<style lang="scss" scoped>
.famous_people {
  background: black;
  color: white;
  padding: 6rem 0;

  &__title {
    font-family: Playfair Display;
    text-transform: unset;
    letter-spacing: 0.02em;
    margin-bottom: 0;

    &.invisible {
      opacity: 0;
    }
  }

  @media (max-width: 991px) {

  }

  @media (max-width: 800px) {
    &__title {
      &.invisible {
        display: none;
      }
    }
  }
}


.swiper_wrapper {
  position: relative;
  background: rgba(12, 12, 14, 1);
  border-radius: 16px;
  border: 1px solid rgba(63, 63, 66, 1);

  .div_static_title {
    position: absolute;
    top: 0.1rem;
    left: 39.3rem;
    z-index: 2;
    background: transparent;
    width: calc(100% - 39.4rem);
    padding-top: 3.2rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  @media (max-width: 991px) {
    .div_static_title{
      font-size: 3;
    }

    .div_static_title{
      left: 27rem;
      width: calc(100% - 27rem);
    }
  }

  @media (max-width: 800px) {
    .div_static_title {
      text-align: center;
      padding: 1.6rem;
      left: 50%;
      transform: translateX(-50%);
      text-wrap: nowrap;
      width: auto;
      font-size: 3.2rem;
    }
  }
}

.str_vertical {
  height: 52rem;

  :deep(.swiper-button-prev), :deep(.swiper-button-next) {
    margin: 0;
    right: 3.2rem;
    height: 60px;

    &:before {
      height: 60px;
    }
  }

  :deep(.swiper-button-prev) {
    transform: translateY(-150%);
    &:before {
      background: url(@/assets/img/ico/str_vertical_top_white.svg) no-repeat top center;
    }
  }

  :deep(.swiper-button-next) {
    transform: translateY(50%);
    &:before {
      background: url(@/assets/img/ico/str_vertical_bottom_white.svg) no-repeat top center;
    }
  }

  @media (max-width: 991px) {
    height: 45rem;
  }

  @media (max-width: 800px) {
    height: auto;

    :deep(.swiper-button-prev), :deep(.swiper-button-next) {
      top: auto;
      bottom: -1rem;
    }

    :deep(.swiper-button-prev) {
      transform: translateX(-250%) rotate(-90deg);
    }

    :deep(.swiper-button-next) {
      transform: translateX(200%) rotate(-90deg);
    }
  }
}

.famous_people_el {
  display: flex;
  overflow: hidden;
  height: 100%;
  gap: 2.4rem;
  padding: 3.2rem 7.4rem 3.2rem 3.2rem;

  .dt1 {
    flex: none;
    width: 36rem;
    overflow: hidden;
    border-radius: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .dt2 {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .div_text {
    margin: auto 0;
    max-width: 46.8rem;
    max-height: 33rem;
    overflow: auto;
  }

  @media (max-width: 991px) {
    padding: 2.4rem 7.4rem 2.4rem 2.4rem;

    .div_text {
      font-size: 1.6rem;
    }

    .div_name {
      font-weight: 700;
    }

    .dt1{
      width: 24.6rem;
      aspect-ratio: 0.7884615384615385;
    }
  }

  @media (max-width: 800px) {
    padding: 7.6rem 1.6rem 7.4rem;
    flex-direction: column;
    height: 100%;

    .dt1 {
      width: 100%;
      max-height: 50rem;
      overflow: hidden;
    }

    .div_text {
      max-width: none;
      max-height: none;
      margin: 0 0 1.2rem;
    }

    .div_flex {
      margin-top: auto;
    }
  }
}
</style>