<template>
  <div  v-if="dataInfo" class="block_video_top">
    <video preload="metadata" v-if="dataInfo.MosVideo" autoplay muted loop playsinline id="videoMainTop">
      <source :src="dataInfo.MosVideo.value.src" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
    </video>
    <container class="banner_container">
      <div class="banner" v-if="dataInfo.MosTitle || dataInfo.MosText">
        <h1 v-if="dataInfo.MosTitle" class="banner__title">{{dataInfo.MosTitle.value}}</h1>
        <div v-if="dataInfo.MosText" class="banner__text">{{dataInfo.MosText.value}}</div>
        <filter-tabs
            v-if="filter.typeCategoryOptions && filter.typeCategoryOptions.length > 0"
            :tabActive="filter.typeCategory"
            :tabList="filter.typeCategoryOptions"
            :tabSample="1"
            tabKey="value"
            label="name"
            class="filter-ico"
            @changeTab="changeFilter"
        ></filter-tabs>
      </div>
    </container>
  </div>
</template>

<script>
// Что бы блок на страницы отображался в зависимости от выбранной категории надо ему добавить id tabCategoryMoskino-[номер идет от 0,1,2,3]

import Container from "@/components/library/Container.vue";
import FilterTabs from "@/components/library/FilterTabs.vue";
import {mapGetters} from "vuex";

export default {
  name: "mainBigBlockKinopark",
  components: {
    FilterTabs,
    Container
  },
  props: {
    dataInfo: {
      type: Object,
      require: true,
    }
  },
  data() {
    return {
      filter: {
        typeCategory: [],
        typeCategoryOptions: [],
      },
    };
  },
  methods: {
    // при монтирование
    initializeAndToggleFilter() {
      if (this.dataInfo && this.dataInfo.MosTabList) {
        this.filter.typeCategoryOptions = [...this.dataInfo.MosTabList].map((tab) => {
          return {value: `tabCategoryMoskino-${tab.indexId.value}`, name: tab.MosTab.value};
        });
        //определение активного таба
        if(this.$route.query && this.dataInfo.query && this.$route.query[this.dataInfo.query.value]){
          this.filter.typeCategory = [ 'tabCategoryMoskino-' + [...this.dataInfo.MosTabList].filter(item => item.query.value == this.$route.query[this.dataInfo.query.value])[0]?.indexId.value];
        } else {
          this.filter.typeCategory = [this.filter.typeCategoryOptions[0].value];
        }
        this.$nextTick(() => {
          this.toggleCategoryMoskinoBlock();
        });
      }
    },

    // фильтрация блоков на странице
    toggleCategoryMoskinoBlock() {
      //смена url query
      if(this.dataInfo.query && this.dataInfo.query?.value){
        history.pushState({}, "", "?" + this.dataInfo.query.value + "=" + [...this.dataInfo.MosTabList].filter(item => item.indexId.value == this.filter.typeCategory[0].replace('tabCategoryMoskino-', ''))[0]?.query.value);
      }
      setTimeout(() => {
        document.querySelectorAll('[id^="tabCategoryMoskino"]').forEach((element) => {
          if (element.id.indexOf(this.filter.typeCategory[0]) !== -1) {
            element.classList.remove("d-none");
          } else {
            element.classList.add("d-none");
          }
        });
      }, 0);
    },
    // событие нажатия на таб
    changeFilter() {
      this.toggleCategoryMoskinoBlock();
      const block = document.querySelector('.block_video_top');
      if(block){
        window.scrollBy({
          top: block.offsetTop + block.offsetHeight - window. scrollY,
          behavior: 'smooth'
        });
      }
    },
  },
  computed: {
    ...mapGetters(["widthSite"]),
  },
  mounted() {
    this.initializeAndToggleFilter();

    // обработка видео
    const video = document.getElementById("videoMainTop");
    if (video) {
      video.muted = true; // Отключение звука для предотвращения блокировки автовоспроизведения
      video.play().catch((error) => {
        //console.error('Автовоспроизведение заблокировано', error);
      });
    }

    // добавляем фон
    document.body.classList.add("cinemapark-moskino");

    this.$nextTick(() => {
      if(document.querySelector('.button_kinokomis_container')) {
        document.body.classList.add('body_video_top');
      }
    });
  },
  beforeUnmount() {
    document.body.classList.remove('body_video_top');
    document.body.classList.remove('cinemapark-moskino');
  },
};
</script>

<style lang="scss">
.body_video_top {
  .block_video_top {
    margin-top: 130px !important;

    @media (max-width: 767px) {
      margin-top: 135px !important;
    }
  }

  .button_kinokomis_container {
    @media (max-width: 1500px) {
      max-width: 1320px;

      .button_kinokomis {
        border-radius: 8px;
      }
    }
    @media (max-width: 1368px) {
      margin-left: 24px;
      margin-right: 24px;
      width: calc(100% - 48px);
    }
    @media (max-width: 767px) {
      width: 100%;
      margin: 0;

      .button_kinokomis {
        border-radius: 0;
      }
    }
  }
}

</style>

<style lang="scss" scoped>

.block_video_top {
  max-width: 1440px;
  min-height: 760px;
  overflow: hidden;
  gap: 48px;
  border-radius: 64px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 80px auto 80px auto;
  padding: 4.8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 103%;
    @media (max-width: 1440px) {
      height: 100%;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .container {
    position: relative;
    //padding: 8rem 6rem;
  }

  @media (max-width: 1500px) {
    max-width: 1320px;
  }
  @media (max-width: 1368px) {
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
  }
  @media (max-width: 767px) {
    width: 100%;
    margin: 80px 0 32px 0;
    border-radius: 16px;
  }
}

:global(.cinemapark-moskino) {
  background-image: url(../assets/img/moskino_fon.svg);
  background-position: center -500px;
  background-repeat: no-repeat;
}
:global(.cinemapark-moskino .smi_black) {
  background-color: rgba(0, 0, 0, 0) !important;
}


.banner_container {
  //padding: 0;
  //max-width: 1320px;
}


.banner {
  //height: calc(100% + 1px);
  //border-radius: 6.4rem;
  //width: 100%;
  //position: relative;


  &__title {
    font-family: Playfair Display;
    font-size: 80px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.01em;
    margin-bottom: 0.8rem;
    max-width: 690px;
  }

  &__text {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0.015em;
    margin-bottom: 32px;
    max-width: 690px;
  }


  @media (max-width: 991px) {

  }

  @media (max-width: 767px) {

    &__title {
      font-size: 48px;
      line-height: 1;
      letter-spacing: 0.01em;
    }
    &__text {
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: 0.015em;
    }

  }
}

.filter-ico {
  display: flex;
  gap: 16px;

  :deep(.filter_tabs_el:first-child) .btn.active  {
    box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.1), 14px 0 14px 0 rgba(0, 0, 0, 0.09), 32px 0 19px 0 rgba(0, 0, 0, 0.05), 58px 0 23px 0 rgba(0, 0, 0, 0.01), 90px 0 25px 0 rgba(0, 0, 0, 0);

  }
  :deep(.filter_tabs_el) .btn.active {
    box-shadow: -4px 1px 9px 0 rgba(0, 0, 0, 0.1), -16px 3px 17px 0 rgba(0, 0, 0, 0.09), -37px 7px 23px 0 rgba(0, 0, 0, 0.05), -66px 12px 27px 0 rgba(0, 0, 0, 0.01), -103px 19px 29px 0 rgba(0, 0, 0, 0);
  }

  :deep(.btn) {
    padding: 12px 16px;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid #ED850A;
    background: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%;
    letter-spacing: 0.036rem;
    color: #FFF;
    &.active {
      background: #ED850A;
      pointer-events: none;
    }
    .text {
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        display: block;
      }
      &.active {
        svg {
          stroke: #ffffff;
        }
      }
    }
  }
}

</style>