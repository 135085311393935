<template>
<div><br>
  <br><br>
  <br></div>
  <container>
    <h1>H1 - text Заголовок 1</h1>
    <h2>H2 - text Заголовок 2 (класс fs2, также fs2n - не жирный)</h2>
    <h3>H3 - text Заголовок 3</h3>
    <h4>H4 - text Заголовок 4</h4>
    <h5>H5 - text Заголовок 5</h5>
    <p>Простой текст 12345 text</p>
    <p class="fs40">fs40 text</p>
    <p class="fs21">fs21 text</p>
    <p class="fs16">fs16 text</p>
    <p class="fs14">fs14 text</p>
    <br>
    <br>
    <h2>Иконки</h2>
    <ul>
        <li>Ненумерованный список</li>
        <li>Ненумерованный список</li>
        <li>Ненумерованный список</li>
    </ul>
    <row>
      <column md="1">
        <img src="/img/ico/play-circle.svg">
      </column>
      <column md="1">
        <img src="/img/ico/shopping-cart.svg">
      </column>
      <column md="1">
        <img src="/img/ico/language.svg">
      </column>
      <column md="2">color-black -> class "img_bl"</column>
      <column md="1" style="padding: 10px; background: #fff;"><img src="/img/ico/shopping-cart.svg" class="img_bl"></column>
      <column md="1" style="padding: 10px; background: #fff;">
        <img src="/img/ico/check-circle.svg" class="img_bl">
      </column>
      <column md="2">color-wh -> class "img_wh"</column>
      <column md="1" style="padding: 10px; background: #000;"><img src="/img/ico/check-circle.svg" class="img_wh"></column>
      <column md="1" style="padding: 10px; background: #000;">
        <img src="/img/ico/chevron-left.svg" class="img_wh">
      </column>
      <column md="1">
        <img src="@/assets/img/ico/trash.svg">
      </column>
    </row>
    <br>
    <row class="row_gap" style="background: #fff;">
      <column md="1">
        <img src="/img/ico/str_vertical_top.svg">
      </column>
      <column md="1">
        <img src="/img/ico/str_vertical_bottom.svg">
      </column>
      <column md="1">
        <img src="/img/ico/check.svg">
      </column>
      <column md="1">
        <img src="/img/ico/check-circle.svg">
      </column>
      <column md="1">
        <img src="/img/ico/chevron-left.svg">
      </column>
      <column md="1">
        <img src="/img/ico/chevron-right.svg">
      </column>
      <column md="1">
        <img src="/img/ico/chevron-down.svg">
      </column>
      <column md="1">
        <img src="/img/ico/chevron-up.svg">
      </column>
      <column md="1">
        <img src="/img/ico/close.svg">
      </column>
      <column md="1">
        <img src="/img/ico/errore_upload.svg">
      </column>
      <column md="1">
        <img src="/img/ico/file.svg">
      </column>
      <column md="1">
        <img src="/img/ico/info.svg">
      </column>
      <column md="1">
        <img src="/img/ico/mail.svg">
      </column>
      <column md="1">
        <img src="/img/ico/maping.svg">
      </column>
      <column md="1">
        <img src="/img/ico/move-up-right.svg">
      </column>
      <column md="1">
        <img src="/img/ico/move-up-right.svg">
      </column>
      <column md="1">
        <img src="/img/ico/phone.svg">
      </column>
      <column md="1">
        <img src="/img/ico/upload.svg">
      </column>
      <column md="1">
        <img src="/img/ico/tg.svg">
      </column>
      <column md="1">
        <img src="/img/ico/vk.svg">
      </column>
      <column md="1">
        <img src="/img/ico/plus_st.svg">
      </column>
      <column md="1">
        <img src="/img/ico/minus_st.svg" class="img_bl">
      </column>
      <column md="1">
        <img src="/img/ico/ico24_calendar_orange.svg">
      </column>
      <column md="1">
        <img src="/img/ico/ico_info_orange.svg">
      </column>
      <column md="1">
        <img src="/img/ico/document_upload_icon.svg">
      </column>
      <column md="1">
        <img src="/img/ico/minus_st.svg" class="img_bl">
      </column>
      <column md="1">
        <img src="/img/ico/sort_ico.svg" class="img_bl">
      </column>
      <column md="1">
        <img src="/img/ico/orange_plus.svg" class="img_bl">
      </column>

    </row>
    <br>
    <br>
    <h2>Иконки спрайт svg</h2>
    <row class="row_gap" style="background: #fff;">
      <column md="1">
        <svg class="svg-icon" width="32" height="32" stroke="#ED850A" >
          <use xlink:href="/svg/icons.svg#closeCircle"></use>
        </svg>
      </column>
      <column md="1">
        <svg class="svg-icon" width="24" height="24" stroke="#5D636F" >
          <use xlink:href="/svg/icons.svg#exit"></use>
        </svg>
      </column>
    </row>
    <br>
    <br>
    <h2>Ссылки</h2>
    <row class="row_gap">
      <column md="3">
        <router-link to="/">Ссылки</router-link>
      </column>
      <column md="3">
        <a href="/dfsfsdfsf">Ссылки</a>
      </column>
      <column md="3">
        <arrow-button>arrow-button (не стандарт)</arrow-button>
      </column>
    </row>
    <br>
    <br>
    <h2>Подсказки</h2>
    <row class="row_gap">
      <column md="3">
        <Popper
            class="light popper_info"
            arrow
            hover
        >
          при наведении
          <template #content>
            1111
          </template>
        </Popper>
      </column>
      <column md="3">
        <Popper
            class="light close_true"
            arrow
            disableClickAway
        >
          с крестиком по нажатию
          <template #content="{ close }">
            <div class="popper-title">Title</div>
            asdasdas asd asdasdd
            <div  @click="close" class="popper-close"><img src="/img/ico/close.svg"></div>
          </template>
        </Popper>
      </column>
      <column md="3">
        <Popper
            class="dark close_true"
            arrow
            disableClickAway
        >
          темная тема
          <template #content="{ close }">
            <div class="popper-title">Title</div>
            asdasdas asd asdasdd
            <div  @click="close" class="popper-close img_wh"><img src="/img/ico/close.svg"></div>
          </template>
        </Popper>
      </column>
      <column md="3">
        <Popper
            class="dark2 close_true"
            arrow
            disableClickAway
        >
          темная тема белая обводка
          <template #content="{ close }">
            <div class="popper-title">Title</div>
            asdasdas asd asdasdd
            <div  @click="close" class="popper-close img_wh"><img src="/img/ico/close.svg"></div>
          </template>
        </Popper>
      </column>
    </row>

<br>
<br>
    <h2>Button</h2>
    <row class="row_gap">
      <column md="3">
        <cl-button>Text</cl-button>
      </column>
      <column md="3">
        <cl-button class="btn-ico" img="/chevron-down.svg">Icon-left</cl-button>
      </column>
      <column md="3">
        <cl-button class="btn-ico-right" img="/chevron-down.svg">Icon-right</cl-button>
      </column>
      <column md="3">
        <cl-button class="btn-ico2" img="/chevron-down.svg"></cl-button>
      </column>
      <column md="3">
        <cl-button type="disabled">Disabled</cl-button>
      </column>
      <column md="3">
        <cl-button disabled="disabled">Disabled</cl-button>
      </column>
      <column md="12"></column>
      <column md="3">
        <cl-button type="light">Text</cl-button>
      </column>
      <column md="3">
        <cl-button type="light color_or">Text</cl-button>
      </column>
      <column md="3">
        <cl-button type="light2">Text</cl-button>
      </column>
      <column md="3">
        <cl-button type="light2 color_or">Text</cl-button>
      </column>
      <column md="3">
        <cl-button type="light3">Text</cl-button>
      </column>
      <column md="3">
        <cl-button class="btn-middle">Height 48</cl-button>
      </column>
      <column md="3">
        <cl-button class="btn-middle btn-ico-right" img="/chevron-down.svg">Height 48 Icon-right</cl-button>
      </column>
      <column md="12">
        <cl-button>Показать контакты<br>+7 (ХХХ) ХХ-ХХ-ХХ</cl-button>
      </column>
    </row>
<br>
<br>
    <h2>Модалка</h2>

    <cl-button @click="modalShow = true">modal</cl-button>
    <cl-modal v-model="modalShow">
      <template v-slot:header>заголовок
      </template>
      <template v-slot:body>тект
      </template>
      <template v-slot:footer>
        <cl-button>кнопка снизу</cl-button>
      </template>
    </cl-modal>
    <br>
    <br>
    <h2>Поля</h2>
    <row class="row_gap">
      <column md="3">
        <base-input placeholder="поиск"/>
      </column>
      <column md="3">
        <input-search
            v-model="nameSearch"
            @search="findByText"
            :placeholder="'что вы ищете?'"
        />
      </column>
      <column md="3">
        <base-input placeholder="поиск" class="input_line"/>
      </column>
      <column md="3">
        <input-search
            v-model="nameSearch"
            @search="findByText"
            class="input_line"
            :placeholder="'что вы ищете?'"
        />
      </column>
      <column md="3">
        <select-and-btn
            v-model="filter.selectSelected"
            :options="filter.selectOptions"
            placeholder="Площадь м2"
            track-by="name"
            label="name"
        />
        <!--          <multiselect-->
        <!--              class="multiselect_multiple"-->
        <!--              v-model="filter.selectSelected"-->
        <!--              track-by="name"-->
        <!--              label="name"-->
        <!--              placeholder="Площадь м2"-->
        <!--              :options="filter.selectOptions"-->
        <!--              :searchable="false"-->
        <!--              :multiple="true"-->
        <!--              :close-on-select="false"-->
        <!--              :clear-on-select="false"-->
        <!--              @select="selectChangeSquare"-->
        <!--          >-->
        <!--            <template v-slot:selection>-->
        <!--              <div v-if="filter.selectSelected.length > 0" class="selected_wrap">-->
        <!--                <div class="multiselect__placeholder">Площадь м2</div>-->
        <!--                <div class="multiselect__length">{{ filter.selectSelected.length }}</div>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--            <template v-slot:afterList>-->
        <!--              <div class="multiselect__bottom">-->
        <!--                <cl-button type="light2" class="" @click="filter.selectSelected = []">Сбросить</cl-button>-->
        <!--                <cl-button>Применить</cl-button>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </multiselect>-->
      </column>
      <column md="3">
        <multiselect
            v-model="selectSelected"
            track-by="name"
            label="name"
            placeholder="выбрать конкурс"
            :options="selectOptions"
            :searchable="false"
            :allow-empty="false"
            @select="selectChange"
            :preselect-first="true"
        ></multiselect>
      </column>
    </row>
    <br>
    <br>
    <h2>Табы</h2>
    <row class="row_gap">
      <column md="4">
        <filter-tabs
            :tabActive="filter.typeEvent"
            :tabList="filter.typeEventOptions"
            @changeTab="changeTabEvent"
            :tabSample="1"
            label="name"
            tabKey="competition"
        ></filter-tabs>
      </column>
      <column md="8">
        <filter-tabs
            :tabActive="filter.typeEvent1"
            :tabList="filter.typeEventOptions1"
            @changeTab="changeTabEvent"
            label="name"
            tabKey="competition"
        ></filter-tabs>
      </column>
    </row>
    <br>
    <br>
    <h2>Карточки</h2>

    <row>
      <column md="2">
        <card card-type="rent">
          <div class="div_img"><img src="/img/logo.svg" alt=""></div>
          <div class="div_tit fs4n">Энтузиаст 1</div>
          <div class="div_info">
            <div class="sp1">Общая площадь:</div>
            <div class="sp2">940 м<sup>2</sup></div>
          </div>
          <div class="div_adress"><img src="/img/ico/maping.svg" alt=""><div class="sp_text">Москва, 2-я ул. Энтузиастов, 5к5</div></div>
        </card>
      </column>
      <column md="2">
        <card card-type="rent" class="card-wh">
          <div class="div_img"><img src="/img/logo.svg" alt=""></div>
          <div class="div_tit fs4n">Для карты</div>
          <div class="div_info">
            <div class="sp1">Общая площадь:</div>
            <div class="sp2">940 м<sup>2</sup></div>
          </div>
          <div class="div_adress"><img src="/img/ico/maping1.svg" alt=""><div class="sp_text">Москва, 2-я ул. Энтузиастов, 5к5</div></div>
        </card>
      </column>
    </row>
    <br>
    <br>
    <h3>Collapse</h3>
    <cl-collapse class="fs3">
      <template v-slot:name>Можно ли получить поддержку без бумажной копии самого патента</template>
      <template v-slot:card>Копия документа</template>
    </cl-collapse>
    <br>
    <br>
    <h3>Контент</h3>
    <div class='content_block'>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur dignissimos eligendi facere harum nisi porro voluptas. Deserunt recusandae, repellendus. Asperiores hic inventore ipsam nulla officiis perspiciatis quod vel voluptates. Facilis!
      </p>
      <div class='row'>
        <div class='col-md-3 col-12'>
          <div checked='true' class='fake_checkbox'>Звукоизоляция</div>
        </div>
        <div class='col-md-3 col-12'>
          <div class='fake_checkbox'>Есть подача воды</div>
        </div>
        <div class='col-md-3 col-12'>
          <div class='fake_checkbox checked'>Есть сливные трапы</div>
        </div>
      </div>
    </div>
    <br>
    <br>
<!--      <animated-number-->
<!--          :number="200" />-->
      <cl-calendar
          v-model="filterDate"
          :attributes="calendarPoints"
          @fromPageCalendar="fromPageCalendar"
      />





    </container>

</template>

<script>




import Row from "@/components/library/Row.vue";

export default {
  name: "StandartEl",
  components: {Row},
  data(){
    return {

      //календарь
      filterDate: null,
      calPoint: {
        "records": [
          {
            "guid": "7e7c73ddffea4ddf9597a5882e4cdb48",
            "shortName": "«V FASHION»-митап «Путь к успеху лежит через потребителя»",
            "dateFrom": "2023-07-20T00:00:00",
            "dateTo": "2023-07-20T00:00:00",
            "place": "Москва, Космодамианская наб., 2",
            "shortInfo": "«V FASHION",
          },
        ]
      },

      modalShow: false,
      nameSearch: "",

      selectSelected:  {
        competition: 0,
        type: "all",
        name: "все конкурсы"
      },
      selectOptions:  [
        {
          competition: 0,
          type: 'all',
          name: "все конкурсы",
        },
        {
          competition: 0,
          type: 'type',
          name: "111",
        },
      ],


      filter: {
        selectSelected:  [],
        selectOptions:  [
          {
            competition: 0,
            type: 'all',
            name: "все конкурсы",
          },
          {
            competition: 0,
            type: 'type',
            name: "111",
          },
        ],


        typeEvent: [ "all" ],
        typeEventOptions: [
          {
            id: 1,
            competition: "all",
            name: "1111",
          },
          {
            id: 1,
            competition: "new",
            name: "22222",
          },
        ],
        typeEvent1: [ ],
        typeEventOptions1: [
          {
            id: 1,
            competition: "111",
            name: "множественный выбор",
          },
          {
            id: 2,
            competition: "222",
            name: "22222",
          },
          {
            id: 3,
            competition: "333",
            name: "22222",
          },
        ],
      }
    }
  },
  computed: {
    calendarPoints() {
      if (this.calPoint && this.calPoint.length > 0) {
        return [
          {
            key: 'today',
            highlight: true,
            dates: new Date(),
          },
          ...this.eventsCalendar.map(event => ({
            dates: {start: new Date(event.dateFrom), end: new Date(event.dateTo)},
            dot: {
              color: 'red',
            },
            popover: {
              label: event.shortName,
            },
            customData: event.shortName,
          })),
        ];
      }
    },
  },
  methods: {
    fromPageCalendar(){
      alert('11');
    },
    findByText(text) {
      alert(text);
    },
    selectChange(val){
      alert(val);
    },
    changeTabEvent(){
      alert(this.filter.typeEvent[0])
    },
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.mb30 {
  margin-bottom: 30px;
}

</style>