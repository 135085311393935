<template>
  <div class="sub_block_black_wrapper wrapper-mtb-40">
    <div class="sub_block_black">
      <container>
        <div class="content-cinema" v-if="dataInfo">
            <div class="dt dt1">
              <template v-if=" dataInfo.dataBlock.btnText && dataInfo.dataBlock.btnLink">
                <div class="content_text">
                  <div class="tit">{{dataInfo.dataBlock.titleBlock.value }}</div>
                  <div class="text" v-html="dataInfo.dataBlock.descBlock.value"></div>
                </div>
                <div class="link_btn">
                  <a class="btn btn-light" :href="dataInfo.dataBlock.btnLink.value" target="_blank">
                    {{ dataInfo.dataBlock.btnText.value }}
                  </a>
                </div>
              </template>
            </div>
            <div class="dt dt2">
              <swiper
                  class="slide100h main-slider arrow_center"
                  :modules="modules"
                  :spaceBetween="24"
                  :slides-per-view="1"
                  :initial-slide="initialSlide"
                  :navigation=" dataInfo.imagesCard.length > 1 ? true : false"
                  :loop="true"
                  :lazy="true"
                  :thumbs="thumbsGal && !thumbsSwiper?.destroyed ? { swiper: thumbsSwiper } : false"
              >
                <swiper-slide v-for="galItem in dataInfo.imagesCard"
                              :key="galItem">
                  <img :src="typeof galItem.image == 'string' ? galItem.image : getImage(galItem.image, 'reference')" loading="lazy" alt="">
                </swiper-slide>
              </swiper>
              <swiper
                  @swiper="setThumbsSwiper"
                  v-if="dataInfo.imagesCard.length > 1"
                  :spaceBetween="12"
                  :slidesPerView="'auto'"
                  :freeMode="true"
                  :watchSlidesProgress="true"
                  :threshold='10'
                  :modules="modules"
                  class="sliderThumbsThumbs sub_swiber"
                  :initial-slide="initialSlide"
              >
                <swiper-slide v-for="galItem in dataInfo.imagesCard"
                              :key="galItem">
                  <div class="div_thumbs">
                    <img :src="typeof galItem.image == 'string' ? galItem.image : getImage(galItem.image, 'reference')" loading="lazy" alt="">
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
      </container>
    </div>
  </div>
</template>

<script>
import Container from "@/components/library/Container";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Thumbs} from "swiper";

export default {
  name: "afishaMoskinoZal",
  components: {
    Container,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      thumbsSwiper: null,
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    dataGallery: {
      type: Array,
      require: true
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
    thumbsGal: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
  setup() {
    return {
      modules: [Navigation, Thumbs],
    }
  },
}
</script>

<style lang="scss" scoped>

  .gray_fon_block{
    padding: 6rem 6rem;
    background: #0C0C0E;
    border-radius: 2.4rem;
  }
  .main-slider{
    height: 38.6rem;
    margin-bottom: 1.2rem;

    &:hover {
      :deep(.swiper-button-next), :deep(.swiper-button-prev) {
        background: rgba(0, 1, 6, 0.1);
        border: rgba(0, 1, 6, 0.1);
        backdrop-filter: blur(8px);
      }
    }
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .sliderThumbsThumbs {
    margin-top: auto;
  }
  .div_thumbs {
    height: 118px;
    width: 150px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 767px) {
    .sliderThumbsThumbs {
      width: 100vw;
      margin-left: -4vw;
    }
    .div_thumbs {
      height: 94px;
      width: 120px;
    }
  }
  .arrow_center {
    padding: 0;
    margin: 0;
    :deep(.swiper-button-next) {
      &::before{
        filter: unset;
      }
      margin-right: 0;
      transform: translate(0, -50%);
      border-radius: 0 1.6rem 1.6rem 0;
      background: none;
      border: none;
      backdrop-filter: none;
      height: 100%;
    }
    :deep(.swiper-button-prev) {
      &::before{
        filter: unset;
      }
      margin-left: 0;
      transform: translate(0, -50%);
      border-radius: 1.6rem 0 0 1.6rem;
      background: none;
      border: none;
      backdrop-filter: none;
      height: 100%;
    }
  }
  .content-cinema{
    display: flex;
    gap: 4rem;
    .dt1{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2.4rem;
      .btn{
        width: 100%;
      }
      .content_text{
        display: flex;
        flex-direction: column;
        .tit{
          font-size: 3.2rem;
          line-height: 150%;
          margin-bottom: 2.4rem;
          @media(max-width: 991px){
            font-size: 2.4rem;
          }
        }
      }
      @media(max-width: 991px){
        width: 100%;
        max-width: unset;
      }
    }
    .dt2{
      max-width: 68.5rem;
      width: 100%;
      padding-top: 2.4rem;
      .swiper{
        .swiper-slide{
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            object-fit: cover;
            width: 100%;
            aspect-ratio: 16 / 9;
          }
        }
      }
      @media(max-width: 991px){
        width: 100%;
        max-width: unset;
      }
    }
  }
  @media(max-width: 991px){
    .container.gray_fon_block {
      padding: 6rem 1.6rem;
    }
    .content-cinema{
      flex-direction: column-reverse;
      .dt1{
        max-width: unset;
        width: 100%;
      }
    }
  }
  @media(max-width: 767px){
    .main-slider{
      height: 23rem;
    }
    .arrow_center .swiper-button-next, .arrow_center .swiper-button-prev {
      display: none !important;
    }
    .swiper-thumbs{
      display: none;
    }
    .content-cinema .dt2 .swiper .swiper-slide img{
      height: 100%;
    }
  }
  .sliderThumbsThumbs .swiper-slide {
    height: 118px;
  }
  .sliderThumbsThumbs{
    margin-top: 2.4rem;
  }
  .main-slider .swiper-slide {
    border-radius: 1.8rem;
    img{
      width: 100%;
    }
  }
</style>

<style lang="scss">
  .content_text .text > p{
    margin-bottom: 1.6rem !important;
  }
</style>