<template>

  <div v-if="dataInfo" class="sub_block_black_wrapper">
    <div class="sub_block_black">
      <container>
        <div class="footer_quest">
          <div class="footer_quest_left">
            <h2 v-if="dataInfo.title" class="fs3">{{ dataInfo.title.value }}</h2>
            <div v-if="dataInfo.phone" class="div_flex div_flex1">
              <div class="div_img">
                <img src="/img/ico/phone.svg" alt="" class="img_wh">
              </div>
              <a :href="'tel:' + dataInfo.phone.value" class="div_phone">{{ dataInfo.phone.value }}</a>
            </div>
            <div v-if="dataInfo.email" class="div_flex div_flex2">
              <div class="div_img">
                <img src="/img/ico/mail.svg" alt="" class="img_wh">
              </div>
              <a :href="'mailto:' + dataInfo.email.value" class="div_email">{{ dataInfo.email.value }}</a>
            </div>
            <h4 v-if="dataInfo.text && (dataInfo.vkLink || dataInfo.telegramLink)">{{ dataInfo.text.value }}</h4>
            <div v-if="dataInfo.vkLink || dataInfo.telegramLink" class="div_flex_social">
              <a v-if="dataInfo.telegramLink" :href="dataInfo.telegramLink.value" class="div_link">
                <img src="/img/ico/tg.svg" alt="">
              </a>
              <a v-if="dataInfo.vkLink" :href="dataInfo.vkLink.value" class="div_link">
                <img src="/img/ico/vk.svg" alt="">
              </a>
            </div>
          </div>
          <div class="footer_quest_right">
            <div class="div_img">
              <img v-if="dataInfo.img" :src="getImage(dataInfo.img)" alt="">
            </div>
          </div>
        </div>
      </container>
    </div>
  </div>

</template>

<script>
import Container from "@/components/library/Container.vue";
import ClButton from "@/components/library/ClButton.vue";

export default {
  name: "footerQuestWithoutForm",
  components: {ClButton, Container},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.footer_quest {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;

  h2 {
    margin-bottom: 1.6rem;
  }

  @media (max-width: 1440px) {

  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
    h2 {
      font-weight: 500;
      font-size: 32px;
    }

  }

  @media (max-width: 767px) {
    h2 {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }
}

.footer_quest_left {
  .div_flex {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    font-size: 24px;
  }

  .div_flex1 {
    margin-bottom: 2.4rem;
  }

  .div_flex2 {
    margin-bottom: 1.6rem;
  }

  .div_img {
    flex: none;
  }

  .div_phone, .div_email {
    color: rgba(237, 133, 10, 1);
  }

  h4 {
    margin-bottom: 0.8rem;
  }

  .div_flex_social {
    display: flex;
    gap: 1.6rem;
  }

  .div_link {
    width: 6.4rem;
    height: 6.4rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 991px) {
    order: 2;
    h4 {
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    h4 {
      font-size: 18px;
    }
    .div_flex {
      font-size: 18px;
    }
  }
}

.footer_quest_right {
  .div_img {
    border-radius: 1.6rem;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1.6rem;
    }
  }

  @media (max-width: 991px) {
    order: 1;
  }

  @media (max-width: 767px) {

  }
}

</style>