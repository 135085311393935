<template>
  <div class="sub_block_black_wrapper wrapper-mtb-40">
    <div class="sub_block_black">
      <container>
<!--        <div class="gray_fon_block">-->
          <div class="card_list_block">
            <div>
              <h2 v-if="dataInfo?.dataBlock?.titleBlock">
                {{ dataInfo.dataBlock.titleBlock.value }}
              </h2>
              <template v-if="!isLoading && dataList?.length > 0">
                <swiper
                    :spaceBetween="24"
                    :navigation="true"
                    :threshold='10'
                    :modules="modules"
                    :loop="widthSite > 992 ? dataList.length > 4 : dataList.length > 2"
                    :slidesPerView="'auto'"
                    :pagination="{
                      type: 'progressbar',
                    }"
                    class="slide100h progress-bar-line"
                    :breakpoints="{
                        '992': {
                          slidesPerView: 3,
                        },
                        '768': {
                          slidesPerView: 2,
                        },
                        '300': {
                          slidesPerView: 1,
                        }
                      }"
                >
                  <swiper-slide
                      v-for='item of dataList'
                  >
                    <div class="card_col">
      <!--                {{ dataList }}-->
                      <card card-type="event" :route-link="'events/item/' + item.guid">
                        <div class="div_img" :class="{'not_img': !item.photos?.[0]?.imageUrl}">
                          <div class="div_tag">
                            <div class="div_tag_el div_tag_el_min" v-if="item.ageLimit || item.ageLimit == 0">{{ item.ageLimit }}+</div>
                            <div class="div_tag_el" v-if="item.isRegistrationInRpp">Требуется регистрация</div>
                          </div>
                          <img v-if="item.photos?.[0]?.imageUrl" :src="item.photos[0].imageUrl" alt="">
                        </div>
                        <div class="div_info">
                          <div class="div_tag2" v-if="item.types && item.types.length > 0"><span v-for="tag in item.types">{{ tag.name }}</span></div>
                          <div class="div_tit fs5" v-if="item.title">{{ item.title }}</div>
                          <div class="div_proj" v-if="item.regEventVenues">
                            <div class="dt">
                              <template v-if="item.priceFrom">
                                <div class="fs4">{{ item.priceFrom }}</div> руб/чел.
                              </template>
                            </div>
                            <div class="dt">
                              <cl-button type="light btn-middle" @click="$emit('remove', item)" :class="{'btn-circle': widthSite < 768}">
                                Выбрать дату
                              </cl-button>
                            </div>
                          </div>
                        </div>
                      </card>
                    </div>
                  </swiper-slide>
                </swiper>
      <!--          <template v-if="dataInfo.types?.value">-->
      <!--            <div class="sub_slider d-block d-lg-none">-->
      <!--              <a class="btn btn-light" target="_blank" :href="`/arenda_kinopomecheniy/pavilony?types=${dataInfo.types.value}`">Посмотреть все</a>-->
      <!--            </div>-->
      <!--          </template>-->
              </template>
            </div>
          </div>
<!--        </div>-->
      </container>
    </div>
  </div>
</template>


<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";

export default {
  name: "listCinema",
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return{
      getParams: {
        ids: "",
      },
      dataList: false,
      teleportDisabled: false,
      GrayFonCard: false,
      GrayFonBlock: false,
      Width50Pers: false,
      idBlockAnchor: "",
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  methods:{
    getPlural(number, one, two, many) {
      if (number % 10 === 1 && number % 100 !== 11) {
        return one;
      } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
        return two;
      } else {
        return many;
      }
    },
    getEvents(isLoadMore) {
      return this.axios
          .get(`/api/data/cinema-events`, {params: this.getParams})
          .then(response => {
            // dateFrom
            if (isLoadMore) {
              this.dataList = [...this.dataList, ...response.data.records];
            } else {
              this.dataList = response.data.records;
            }
            this.data = response.data;

            this.dataList.forEach((item, index) => {
              if (this.dataInfo.dataCard[index].titleEvent !== undefined && this.dataInfo.dataCard[index].titleEvent){
                this.dataList[index].Name = this.dataInfo.dataCard[index].titleEvent.value;
              }
              if (this.dataInfo.dataCard[index].descEvent !== undefined && this.dataInfo.dataCard[index].descEvent){
                this.dataList[index].Description = this.dataInfo.dataCard[index].descEvent.value;
              }
            })

            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    collectedGuids(elem){
      let k = []
      elem.forEach( el => {
        k.push(el.guidEvent.value);
      })
      this.getParams.ids = k.join(',');
      this.getEvents();
    }
  },
  mounted() {
    this.collectedGuids(this.dataInfo.dataCard);
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  },
}
</script>

<style lang="scss" scoped>
  .row{
    justify-content: center;
    > div{
      height: 100%;
    }
  }
  .gray_fon_block{
    padding: 6rem;
    background: #0C0C0E;
    border-radius: 2.4rem;
    max-width: 144.4rem;
  }

  h2, .sub_h2{
    text-align: center;
  }
  h2{
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .sub_h2{
    font-size: 1.8rem;
    line-height: 150%;
    color: #6B7280;
    margin-bottom: 3.2rem;
  }
  .card{
    background: #17191C;
    height: 100%;
  }
  .title_desc{
    text-align: center;
    .name_event{
      font-size: 2.4rem;
      line-height: 150%;
      margin-bottom: 1.6rem;
    }
    .desc_event{
      color: #6B7280;
      font-size: 1.6rem;
      margin-bottom: 3.2rem;
    }
  }

.card-event .div_info .div_proj .dt{
  margin-left: unset;
}

.div_proj {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  .dt{
    display: flex;
    align-items: baseline;
    gap: 1.2rem;
    margin-left: 0;
    flex-wrap: nowrap;
    h3{
      margin-bottom: 0;
    }
  }
}

@media(max-width: 991px){
  .card_list_block .container.gray_fon_block{
    padding: 6rem 1.6rem;
  }
}

</style>