<template>
  <container>
    <div class="h1_lk">
      <h1 class="hasSubtit hasSubtitProf">Мои заявки</h1>
      <div class="btn-holder">
        <cl-button type="light btn-middle" @click="modalAddProj = true">
          <img src="/img/ico/orange_plus.svg" >
          Подать новую заявку
        </cl-button>
      </div>
    </div>
    <div class="filter_lk">
      <div class="filter_hold">
        <input-search
            v-model="nameSearch"
            @search="findByText"
            class="h48px"
            :placeholder="'Введите номер для поиска'"
        />
        <cl-button type="light btn-ico-one btn-tag" @click="toggleFilter" :class="{ 'active' : filterShow}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke="white" fill="transparent">
            <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </cl-button>
      </div>
      <div class="sort_filter" @click="toggleDataSort" :class="{'sortDown': this.sortParam, 'disabled': isLoadingMore || isLoading }">
        <img src="/img/ico/sort_ico.svg" class="img_wh">
        <div class="text">
          Сортировать:
        </div>
        <div class="sort_name">
          по дате заявки
        </div>
      </div>
    </div>
    <div class="filter_block filter_block2" v-if="filterShow">
      <row>
        <column class="d-block d-md-none">
          <h2 class="mb-0 text-center fw500">Фильтр заявок</h2>
        </column>
        <column sm="12" lg="3" v-if="getUser.currentCompany !== null">
          <multiselect
              placeholder="Проект"
              v-model="filter.selectProjectStatus"
              :options="filter.selectNameProjectStatus"
              track-by="guid"
              label="title"
              class="light"
              @select="selectProject"
              :searchable="false"
              :allow-empty="false"
              :disabled="isLoadingMore || isLoading"
          ></multiselect>
        </column>
        <column sm="12" lg="3">
          <multiselect
              v-model="filter.selectSelectedStatus"
              :options="filter.selectOptionsStatus"
              placeholder="Статус"
              track-by="idStatus"
              label="title"
              class="light"
              @select="selectChangeStatus"
              :searchable="false"
              :allow-empty="false"
              :disabled="isLoadingMore || isLoading"
          ></multiselect>
        </column>
        <column sm="12" lg="3">
          <multiselect
              v-model="filter.selectSelectedFinansial"
              :options="filter.selectOptionsFinansial"
              placeholder="Тип заявки"
              track-by="value"
              label="name"
              class="light"
              @select="selectChangeFinansial"
              :searchable="false"
              :allow-empty="false"
              :disabled="isLoadingMore || isLoading"
          ></multiselect>
        </column>
        <column sm="12" lg="3">
          <input-search
              v-model="filter.nameFioSearch"
              :placeholder="'Поиск по ФИО'"
              class="h48px form-filter"
              @search=" this.getMeasureSupportList()"
              :disabled="isLoadingMore || isLoading"
          />
        </column>
        <column lg="3"></column>
        <column class="bnt-block d-flex d-md-none">
          <cl-button type="default" class="btn-middle" @click="toggleFilter">Применить</cl-button>
          <a class="link clOr" @click="clearFilter">Сбросить</a>
        </column>
      </row>
    </div>
    <template v-if="dataInfo">
      <cl-modal modalType="helper" v-model="modalAddProj" class="modal_form">
        <div class="title" v-if="dataInfo.title?.value">{{ dataInfo.title.value }}</div>
        <row>
          <template v-for="item in dataInfo.list">
            <column md="6" v-if="item.isNotAuth?.value && !getUser">
              <card cardType="helper">
                <div class="ico" v-if="item.image">
                  <img :src="getImage(item.image)" alt="">
                </div>
                <div class="card_title" v-if="item.title?.value">{{ item.title.value }}</div>
                <cl-button type="light" class="btn-middle" :link="true" :href="item.link?.value" target="_blank">Выбрать</cl-button>
              </card>
            </column>
            <column md="6" v-else-if="item.link?.value && !getUser">
              <card cardType="helper">
                <div class="ico" v-if="item.imageDisable">
                  <img :src="getImage(item.imageDisable)" alt="">
                </div>
                <div class="card_title" v-if="item.title?.value">{{ item.title.value }}</div>
                <cl-button type="disable-background" class="btn-middle" >Выбрать</cl-button>
              </card>
            </column>
            <column md="6" v-else-if="item.link?.value && !getUser?.currentCompany && item.isCompany?.value">
              <card cardType="helper">
                <div class="ico" v-if="item.imageDisable">
                  <img :src="getImage(item.imageDisable)" alt="">
                </div>
                <div class="card_title" v-if="item.title?.value">{{ item.title.value }}</div>
                <cl-button type="disable-background" class="btn-middle" >Выбрать</cl-button>
              </card>
            </column>
            <column md="6" v-else>
              <card cardType="helper">
                <div class="ico" v-if="item.image">
                  <img :src="getImage(item.image)" alt="">
                </div>
                <div class="card_title" v-if="item.title?.value">{{ item.title.value }}</div>
                <cl-button type="light" class="btn-middle" :link="true" :href="item.link?.value" target="_blank">Выбрать</cl-button>
              </card>
            </column>
          </template>
        </row>
      </cl-modal>
    </template>
    <loader
        v-if="isLoading"
        :animation-duration="3000"
        :size="60"
    ></loader>
    <not-info v-else-if="applications && applications.length == 0 && (Object.keys(getParams).length > 1 || (Object.keys(getParams).length == 1 && getParams.measureSupports != measureSupportStart))"
              :title="Object.keys(getParams).length == 0 ? `Здесь пока ничего нет` : `Ничего не нашлось`"
              :text="Object.keys(getParams).length == 0 ? `В данный момент заявки отсутствуют` : `Нет результатов, удовлетворяющих критериям поиска. Попробуйте сбросить фильтры`"
    >
      <template v-slot:btn v-if="Object.keys(getParams).length > 0">
        <div class="div_btn">
          <cl-button @click="this.deleteParams">Сбросить фильтры</cl-button>
        </div>
      </template>
    </not-info>
    <template v-if="!isLoading && applications && applications.length > 0">
      <row class="row_mb">
        <transition-group name="fade-base2">
          <column
              v-for="(event, index) in applications"
              :key="event.id"
          >
            <card-support-measure :event="event"/>
          </column>
        </transition-group>
      </row>
      <div v-if="data?.page < data?.pages" class="show_more">
        <loader
            v-if="isLoadingMore"
            :animation-duration="1500"
            :size="55"
        ></loader>
        <cl-button v-else @click="loadMore" type="light sm-w-100">показать еще</cl-button>
      </div>
    </template>

    <div v-if="!isLoading && (applications && applications.length == 0) && Object.keys(getParams).length == 1 && getParams.measureSupports == measureSupportStart" class="fs4n text_not_info">В данный момент заявки отсутствуют </div>

  </container>

</template>

<script>
import {mapGetters} from "vuex";
import cardSupportMeasure from "@/components/CardSupportMeasure.vue";
import {nextTick} from "vue";

export default {
  name: "MyApplications",
  components: {cardSupportMeasure},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      data: false,
      applications: null,

      filterShow: false,

      isLoadingMore: false,
      isLoading: true,
      modalAddProj: false,
      getParams: {
        sortExpression:"created DESC"
      },
      measureSupportStart: '',
      projects: {},
      dataFilterMeasure: [],
      filter: {
        selectSelectedStatus: [],
        selectOptionsStatus: [
          {
            id: 0,
            idStatus: 1,
            title: "Новая",
          },
          {
            id: 1,
            idStatus: 2,
            title: "В работе",
          },
          {
            id: 2,
            idStatus: 3,
            title: "Завершена",
          },
          {
            id: 3,
            idStatus: 4,
            title: "Одобрена",
          },
          {
            id: 4,
            idStatus: 5,
            title: "Отклонена",
          },
          {
            id: 5,
            idStatus: 6,
            title: "Черновик",
          },
        ],
        selectSelectedFinansial: [],
        selectOptionsFinansial: [],
        selectProjectStatus: [],
        selectNameProjectStatus: [],
        code:[],
        nameFioSearch:"",
      },
      controller: null
    }
  },

  methods: {
    getMeasureSupportList(isLoadMore) {
      if (!isLoadMore) {
        delete this.getParams.page;
        this.isLoading = true;
      }
      console.log(this.filter.nameFioSearch)
      if ((this.filter.nameFioSearch !== "") && (this.filter.nameFioSearch !== undefined)){
        this.getParams.fio = this.filter.nameFioSearch;
      }
      return this.axios
          .get(`/api/get/measure-services`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            if (isLoadMore) {
              this.applications = [...this.applications, ...response.data.records];
            } else {
              this.applications = response.data.records;
              this.showLoader = false;
            }
            this.data = response.data;
            this.isLoading = false;
            // if(!this.filterShow && this.applications && this.applications.length > 0){
            //   this.filterShow = true;
            // }
          })
          .catch(error => {
            this.showError(error);
          });
    },
    loadMore() {
      this.isLoadingMore = true;
      this.getParams.page = ++this.data.page;
      this.$nextTick(() => {
        this.getMeasureSupportList(true).then(() => {
          this.isLoadingMore = false
        })
      });
    },
    toggleDataSort(){
      if (this.getParams.sortExpression == "created DESC"){
        this.getParams.sortExpression = "created ASC";
        this.sortParam = true;
      } else {
        this.getParams.sortExpression = "created DESC";
        this.sortParam = false;
      }
      this.getMeasureSupportList();
    },
    // получение мер
    getFinansial() {
      return this.axios
          .get(`/api/data/applications-all`, {signal: this.controller.signal})
          .then(response => {
            this.filter.selectOptionsFinansial = response.data;
            let idList = [];
            [...this.filter.selectOptionsFinansial].map(item => {
              idList.push(item.value);
            });
            this.$nextTick(() => {
              this.measureSupportStart = idList.join();
              this.getParams.measureSupports = this.measureSupportStart;
              this.filter.selectOptionsFinansial.unshift({
                "name": "Все меры",
                "value": this.measureSupportStart
              });

              this.getMeasureSupportList();
            });
          })
          .catch(error => {
            this.showError(error);
          });
    },
    // Тип меры
    selectChangeFinansial() {
      if (this.filter.selectSelectedFinansial.length < 1) {
        delete this.getParams.measureSupports
      } else {
        this.getParams.measureSupports = this.filter.selectSelectedFinansial.value;
      }
      this.getMeasureSupportList();
    },
    //Статус
    selectChangeStatus() {
      if (this.filter.selectSelectedStatus.length < 1) {
        delete this.getParams.claimStatus
      } else {
        this.getParams.claimStatus = this.filter.selectSelectedStatus.idStatus;
      }
      this.getMeasureSupportList();
    },
    selectProject(){
      if (this.filter.selectProjectStatus.length < 1) {
        delete this.getParams.filmProjectGuids
      } else {
        this.getParams.filmProjectGuids = this.filter.selectProjectStatus.guid;
      }
      this.getMeasureSupportList();
    },
    deleteParams() {
      this.filter.selectSelectedStatus = [];
      this.filter.selectSelectedFinansial = [];
      this.getParams = {};
      this.getParams.measureSupports = this.measureSupportStart;
      this.filter.nameFioSearch = "";
      this.getMeasureSupportList();
    },
    toggleFilter(){
      if(this.filterShow == true){
        this.filterShow = false;
      } else {
        this.filterShow = true;
      }
    },
    findByText(text){
      if (this.filter.code.length < 1) {
        this.getParams.code = text;
      } else {
        delete this.getParams.code
      }
      // console.log(this.getParams)
      this.getMeasureSupportList();
    },

    getProjects(){
      this.axios
        .get(`/api/get/list/film/projects/`)
        .then(response => {
          this.projects = response.data.records;
          if (this.projects.length > 0){
            this.projects.forEach(el => {
              this.dataFilterMeasure.push( {title: el.name, guid: el.guid })
            })
          }
          this.filter.selectNameProjectStatus = this.dataFilterMeasure;
        })
        .catch(error => {
          this.showError(error);
        })
        .finally(() => {
          this.loaderProjects = false;
        });
    },
    clearFilter(){
      this.toggleFilter();
      this.deleteParams();
    }
  },
  computed: {
    ...mapGetters([
      'widthSite',
      'getUser',
      'getIsAuthorization',
      'getIsCompany',
      'getCompany',
    ])
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    //this.getMeasureSupportList();
    this.getFinansial();
    if (this.getUser.currentCompany !== null){
      this.getProjects();
    }
  },
  beforeUnmount() {
    // отмена запросов
    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style scoped lang="scss">
.text_not_info {
  color: #C7CAD1;
}
.w252 {
  @media (min-width: 600px) {
    width: 252px;
  }
}
.filter_block2 {
  margin-bottom: 24px;
  .row{
    width: 100%;
  }
}
.filter_lk{
  margin-bottom: 3.2rem;
  display: flex;
  width: 100%;
  gap: 3.2rem;
  .filter_hold{
    display: flex;
    gap: 2.4rem;
    width: 100%;
    .btn.btn-ico-one{
      height: 48px;
    }
  }
  .sort_filter{
    display: flex;
    gap: .8rem;
    svg, img{
      margin-right: .8rem;
    }
    &.disabled{
      pointer-events: none;
    }
  }
  .search_pole{
    width: 100%;
    //.form-control {
    //  font-size: 1.6rem !important;
    //}
  }
  .sort_filter{
    display: flex;
    align-items: center;
    padding-right: 3rem;
    img{
      -moz-transform: scale(1, -1);
      -webkit-transform: scale(1, -1);
      transform: scale(1, -1);
    }
    &.sortDown{
      img{
        -moz-transform: unset;
        -webkit-transform: unset;
        transform: unset;
      }
    }
    .sort_name{
      white-space: nowrap;
      text-decoration: underline;
    }
  }
}

.h1_lk{
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
  align-items: center;
  margin-bottom: 2rem;
  h1{
    margin-bottom: 0;
  }
}

@media(max-width: 991px){
  .filter_block .row > div {
    padding: 0;
  }
}

@media(max-width: 767px){
  .h1_lk{
    flex-direction: column;
    h1, div{
      width: 100%;
    }
    .btn{
      width: 100%;
    }
  }
  .filter_lk{
    flex-direction: column;
  }
  .filter_block.filter_block2 {
    position: absolute;
    top: 60px;
    background: black;
    border-radius: 0;
    height: calc(100vh - 56px);
    width: 100vw;
    left: 0;
    padding: inherit;
    display: flex;
    justify-content: center;
    .bnt-block{
      flex-direction: column;
      align-items: center;
      gap: 2.4rem;
      .btn{
        width: 100%;
      }
    }
  }
}

.h48px.form-filter .form-control{
  height: 52px;
}


</style>