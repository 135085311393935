<template>
  <side-info-with-img v-if='blockComponent == "sideInfoWithImg"' :dataInfo="dataInfo" :id="id"/>
  <famous-people-v2 v-if='blockComponent == "famousPeopleV2"' :dataInfo="dataInfo" :id="id"/>
  <famous-people-v3 v-if='blockComponent == "famousPeopleV3"' :dataInfo="dataInfo" :id="id"/>
  <smi-about-us-v2 v-if='blockComponent == "smiAboutUsV2"' :dataInfo="dataInfo" :id="id" class="smi_black"/>
  <smi-about-us-progress-bar v-if='blockComponent == "smiAboutUsProgressBar"' :dataInfo="dataInfo" :id="id" class="smi_black"/>
  <side-info-with-img-toggle v-if='blockComponent == "sideInfoWithImgToggle"' :dataInfo="dataInfo" :id="id"/>
  <gallery-buttons v-if='blockComponent == "galleryButtons"' :dataInfo="dataInfo" :id="id"/>
  <kinopark-rules v-if='blockComponent == "kinoparkRules"' :dataInfo="dataInfo" :id="id"/>
  <collapse-list v-if="blockComponent == 'questionsWithUnder'" :dataInfo="dataInfo" :id="id" class="questions_with_under sub_block_black sub_block_black_wrapper_m"/>
  <kinopark-map v-if="blockComponent == 'kinoparkMap'" :dataInfo="dataInfo" :id="id"/>
  <footer-quest-without-form v-if="blockComponent == 'footerQuestWithoutForm'" :dataInfo="dataInfo" :id="id"/>
  <slider-with-services-description v-if="blockComponent == 'sliderWithServicesDescription'" :dataInfo="dataInfo" :id="id"/>
</template>

<script>
import SideInfoWithImg from "@/components/sideInfoWithImg.vue";
import FamousPeopleV2 from "@/components/famousPeopleV2.vue";
import SmiAboutUsV2 from "@/components/smiAboutUsV2.vue";
import SideInfoWithImgToggle from "@/components/sideInfoWithImgToggle.vue";
import GalleryButtons from "@/components/galleryButtons.vue";
import FamousPeopleV3 from "@/components/famousPeopleV3.vue";
import SmiAboutUsProgressBar from "@/components/smiAboutUsProgressBar.vue";
import KinoparkRules from "@/components/kinoparkRules.vue";
import CollapseList from "@/components/collapseList.vue";
import KinoparkMap from "@/components/kinoparkMap.vue";
import FooterQuestWithoutForm from "@/components/footerQuestWithoutForm.vue";
import SliderWithServicesDescription from "@/components/sliderWithServicesDescription.vue";

export default {
  name: "templateDynamic5",
  components: {
    SliderWithServicesDescription,
    FooterQuestWithoutForm,
    KinoparkMap,
    CollapseList,
    KinoparkRules,
    SmiAboutUsProgressBar,
    FamousPeopleV3, GalleryButtons, SideInfoWithImgToggle, SmiAboutUsV2, FamousPeopleV2, SideInfoWithImg},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
    id: {
      type: String,
    },
    blockComponent: {
      type: String,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>