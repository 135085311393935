<template>
  <container>
    <div class="menu_wrapper">
      <a target="_blank" v-for="(item, index) in dataInfo.menuItems" :key="index" :href="item.pdf.value.src" class="menu_item">
        <img class="menu_img" v-if="item.img" :src="item.img.value.src.big.url" alt="">
        <div class="menu_txt" v-if="item.text">{{ item.text.value }}</div>
      </a>
    </div>
  </container>
</template>

<script>

export default {
  name: "restaurantMenu",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      menuItems: [],
    }
  },
}

</script>

<style lang="scss" scoped>

.menu_wrapper {
  display: flex;
  gap: 27px;
  align-items: center;
  justify-content: center;
  padding: 80px 0;

  .menu_item {
    display: flex;
    gap: 1.6rem;
    height: 128px;
    border: 1px solid #6B7280;
    max-width: 279.75px;
    padding: 24px 16px 24px 16px;
    border-radius: 12px;
    align-items: center;

    .menu_img {
      width: 80px;
      height: 80px;
      object-position: center;
      object-fit: contain;
      border-radius: 12px;
    }

    .menu_txt {
      max-width: 129px;
      font-family: Raleway;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.02em;
      text-decoration: underline;
      text-transform: uppercase;
    }
  }

  @media (max-width: 1300px) {
    flex-wrap: wrap;
  }
}

</style>