<template>
  <div class="sub_block_black_wrapper">
    <div class="sub_block_black">
      <container>
        <div class="guides">
          <h3 class="div_tit" v-if="dataInfo.title?.value">
            {{ dataInfo.title.value }}
          </h3>
          <swiper class="progress-bar-line"
              :modules="modules"
              :slidesPerView="'auto'"
              :spaceBetween="24"
              :pagination="{
                type: 'progressbar',
              }"
              :navigation="true"
          >
            <swiper-slide v-for="(item, key) of dataInfo.cardsList" :key="key">
              <div class="img" v-if="item.cardImage">
                <img :src="getImage(item.cardImage)" alt="">
              </div>
              <h4 class="tit" v-if="item.cardName?.value">
                {{ item.cardName.value }}
              </h4>
              <div class="desc" v-if="item.cardAbout?.value" v-html="item.cardAbout.value"></div>
              <div class="categories" v-if="item.categoriesList">
                <div class="category fs16" v-for="categoryEl of item.categoriesList">{{categoryEl.category.value}}</div>
              </div>
            </swiper-slide>
          </swiper>
    <!--      <div class="swiper-btns">
            <div ref="prev" class="swiper-btn swiper-button-prev" @click="moveProgressBarBack">
              <img src="/img/ico/move-left.svg">
            </div >
            <div ref="next" class="swiper-btn swiper-button-next" @click="moveProgressBarForward">
              <img src="/img/ico/move-right2.svg">
            </div>
            <div class="progress-bar">
              <div class="bar" :style="{'width': lastSlideShown/dataInfo.cardsList.length * 100 + '%'}"></div>
            </div>
          </div>-->
        </div>
      </container>
    </div>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";

export default {
  components: {Container, Swiper, SwiperSlide},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return{
      slidesPerView: 4,
    }
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  },
};
</script>

<style lang="scss" scoped>

.sub_block_black{
  overflow: hidden;
}
.guides {
  overflow: visible;
  .swiper {
    overflow: visible;
  }
  .div_tit {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 3.2rem;
  }
  .swiper-slide {
    width: 27.4rem;
    max-width: 27.4rem;
  }
  .img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-top: 129.6%;
    border-radius: 3.2rem;
    margin-bottom: 1.6rem;
    background: url(../assets/img/jury_background.png) no-repeat;
    background-size: cover;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      object-fit: cover;
    }
  }
  .tit {
    margin-bottom: 4px;
    text-transform: uppercase;
    font-size: 2.4rem;
  }
  .desc {
    font-size: 1.4rem;
    line-height: 150%;
    color: #53595E;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 0.4rem;
  }

  .categories{
    .category{
      color: #53595E;
      margin-bottom: 0.4rem;
      padding-left: 1.6rem;
      position: relative;
      line-height: 2.4rem;

      &::before{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 0.8rem;
        width: 0.8rem;
        background-color: #ED850A;
        border-radius: 50%;
      }

      &::after{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 0.4rem;
        height: 0.4rem;
        width: 0.4rem;
        background-color: #0C0C0E;
        border-radius: 50%;
      }
    }
  }

  .swiper-btns {
    display: flex;
    gap: 6.4rem;
    align-items: center;
    .swiper-btn {
      position: static;
      &:before {
        content: unset;
      }
    }
    .swiper-button-disabled {
      display: flex;
    }
    img {
      width: 4.8rem;
      height: 4.8rem;
      object-fit: contain;
    }

    .progress-bar{
      flex-grow: 2;
      height: 2px;
      background: rgb(130,138,143);

      .bar{
        height: 100%;
        width: 50%;
        background-color: #ED850A;
        transition: 0.2s;
      }
    }
  }
  @media (max-width: 991px) {
    padding:3.2rem;
    .swiper-slide {
      width: 29.5rem;
      max-width: 29.5rem;
    }
    .div_tit {
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 150%;
    }
  }
  @media (max-width: 767px) {
    padding: 1.6rem;
    .swiper-btns{
      display: none;
    }

    .div_tit{
      font-size: 2.4rem;
      margin-bottom: 1.2rem;
    }
  }
}
</style>