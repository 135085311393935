<template>
  <div v-if="dataInfo" class="sub_block_black_wrapper">
    <div class="sub_block_black kinopark_map">
      <container><h2 v-if="dataInfo.title" class="fs3">{{dataInfo.title.value}}</h2></container>
      <yandex-map
          @map-was-initialized="mapLoaded"
          :settings="settings"
          :coords="coords"
          :controls="[]"
          :zoom="zoom"
          class="ya-map-kinopark"
      >
        <ymap-marker
            :coords="markerCoords"
            :icon="markerIcon"
            :options="markerOptions"
        >
        </ymap-marker>
      </yandex-map>
      <container>
        <div class="div_flex">
          <div class="div_img">
            <img src="/img/ico/share-location.svg" alt="">
          </div>
          <div v-if="dataInfo.text" class="div_text">{{dataInfo.text.value}}</div>
        </div>
        <div v-if="dataInfo.address" class="div_address">{{dataInfo.address.value}}</div>
      </container>

    </div>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";
import {yandexMap, ymapMarker, loadYmap} from 'vue-yandex-maps'

let mapInstance;
export default {
  name: "kinoparkMap",
  components: {Container, yandexMap, ymapMarker},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      coordsRegex: /^[0-9]+(\.[0-9]+)?,[0-9]+(\.[0-9]+)?$/,
      settings: {
        apiKey: 'c99b23cc-9919-4a5f-b713-5653ee3e0cd5',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },
      zoom: 14,
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: '/img/ico/marker.svg',
        imageSize: [32, 32],
        imageOffset: [-32, -32],
        contentOffset: [-32, -32],
      },
      markerOptions: {
        balloonPanelMaxMapArea: Infinity,
      },
    }
  },
  methods: {
    mapLoaded(myMap) {
      mapInstance = myMap;
    },
  },
  computed: {
    coords() {
      return this.dataInfo && this.dataInfo.coords && this.coordsRegex.test(this.dataInfo.coords.value.replace(/\s+/g, '')) ? this.dataInfo.coords.value.replace(/\s+/g, '').split(",").map((item) => {return Number(item)}) : [0, 0];
    },

    markerCoords() {
      return this.dataInfo && this.dataInfo.markerCoords && this.coordsRegex.test(this.dataInfo.markerCoords.value.replace(/\s+/g, '')) ? this.dataInfo.markerCoords.value.replace(/\s+/g, '').split(",").map((item) => {return Number(item)}) : [0, 0];
    }
  }
}
</script>

<style lang="scss" scoped>

.kinopark_map {
  background: rgba(12, 12, 14, 1);
  border-radius: 3.2rem;
  padding-left: 0;
  padding-right: 0;

  h2 {
    margin-bottom: 3.2rem;
    text-align: center;
  }

  .div_flex {
    display: flex;
    align-items: center;
    gap: 0.8rem;
   // padding: 0 12rem;
    margin-bottom: 0.8rem;
  }

  .div_address {
    //padding-left: 12rem;
  }

  @media (max-width: 991px) {
    //padding: 3.2rem 0;
    h2 {
      font-weight: 500;
      font-size: 3.2rem;
    }
  }

  @media (max-width: 767px) {
    //padding: 1.6rem 0;
    h2 {
      font-weight: 500;
      font-size: 2.4rem;
    }
  }
}

.ya-map-kinopark {
  width: 100%;
  height: 45rem;
  border-radius: 1.2rem;
  margin-bottom: 3.2rem;
}
</style>