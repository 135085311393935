<template>
  <div class="sub_block_black_wrapper" v-if="dataInfo">
    <div class="kinopark_slider_with_serv_desc sub_block_black">
      <container>
        <div v-if='dataInfo.photos && dataInfo.photos.length > 0' class='sliderThumbs'>
          <div class="sliderThumbsMainWrapper">
            <div ref="prev" class="swiper-button-prev"></div>
            <div ref="next" class="swiper-button-next"></div>
            <swiper
                ref="mySwiperRent"
                :spaceBetween="10"
                :navigation="{
                    prevEl: prev,
                    nextEl: next,
                  }"
                :thumbs="{ swiper: thumbsSwiper }"
                :threshold='10'
                :modules="modules"
                :loop="dataInfo.photos.length > 1 ? true : false"
                class="sliderThumbsMain"
                @activeIndexChange="updateActiveIndex"
            >
              <swiper-slide v-for="photo of dataInfo.photos" :key="photo">
                <div class="img_wrap_rent">
                  <img :src="getImage(photo.photo)" alt=""/>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <swiper
              ref="thumbsSwiper"
              @swiper="setThumbsSwiper"
              :spaceBetween="24"
              :slidesPerView="'auto'"
              :freeMode="true"
              :watchSlidesProgress="true"
              :threshold='10'
              :modules="modules"
              class="sliderThumbsThumbs"
              :initialSlide="activeIndex"
              @slideChange="updateMainSwiper"
          >
            <swiper-slide v-for="(photo, index) of dataInfo.photos" :key="photo" :class="{ slide_new: index === realIndex }">
              <img :src="getImage(photo.photo)" alt=""/>
            </swiper-slide>
          </swiper>
        </div>
        <div class="kinopark_slider_with_serv_desc__serv_desc">
          <h2 v-if="dataInfo.title" class="div_title">{{dataInfo.title.value}}</h2>
          <div class="div_info">
            <div class="div_info_left">
              <div v-if="dataInfo.text" class="fs16 div_text" v-html="dataInfo.text.value"></div>
              <h4 v-if="dataInfo.servicesTitle" class="div_serv_title">{{dataInfo.servicesTitle.value}}</h4>
              <div v-if="dataInfo.services && dataInfo.services.length > 0" class="div_serv">
                <div v-for="service of dataInfo.services" class="div_serv_el">
                  <div v-if="service.icon" class="div_serv_img">
                    <img :src="getImage(service.icon)" alt="">
                  </div>
                  <div v-if="service.text" class="div_serv_text">{{service.text.value}}</div>
                </div>
              </div>
            </div>
            <div class="div_info_right">
              <div v-if="dataInfo.img" class="div_img">
                <img :src="getImage(dataInfo.img)" alt="">
              </div>
            </div>
          </div>
        </div>
      </container>
    </div>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {FreeMode, Navigation, Thumbs} from "swiper";
import {ref} from "vue";

export default {
  name: "sliderWithServicesDescription",
  components: {SwiperSlide, Swiper, Container},
  data() {
    return {
      thumbsSwiper: null,
      activeIndex: 0,
      realIndex: 0,
      isLoadingVideo: true,
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
  },
  methods: {
    setThumbsSwiper(swiperInstance) {
      this.thumbsSwiper = swiperInstance;
    },

    updateActiveIndex(swiperInstance) {
      this.realIndex = swiperInstance.realIndex;
      this.activeIndex = swiperInstance.activeIndex;
    },

    updateMainSwiper(swiperInstance) {
      if (this.$refs.mySwiperRent && this.$refs.mySwiperRent.swiper) {
        this.$refs.mySwiperRent.swiper.slideTo(swiperInstance.realIndex);
      }
    },
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [FreeMode, Navigation, Thumbs],
      prev,
      next,
    };
  },
}
</script>

<style lang="scss" scoped>
.kinopark_slider_with_serv_desc {

  .sliderThumbs {
    margin-bottom: 6.4rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;

    &MainWrapper {
      position: relative;

      .swiper-button-prev {
        left: -4.8rem;
      }

      .swiper-button-next {
        right: -4.8rem;
      }
    }

    &Main {
      .swiper-slide {
        background: none;
      }
      .img_wrap_rent {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.6rem;
        }
      }
    }

    &Thumbs {

      .swiper-slide {

        &.swiper-slide-thumb-active img {
          border: none;
        }

        &.slide_new img {
          border: 2px solid #ED850A;
        }
      }
    }
  }

  &__serv_desc {
    .div_title {
      margin-bottom: 0.8rem;
    }

    .div_info {
      display: flex;
      gap: 2.4rem;
    }

    .div_info_left {
      width: calc(50% - 1.2rem);
    }

    .div_info_right {
      width: calc(50% - 1.2rem);
    }

    .div_text {
      margin-bottom: 1.6rem;

      :deep(p) {
        margin-bottom: 0.8rem;
      }

      :deep(p):last-child {
        margin-bottom: 0;
      }
    }

    .div_serv_title {
      text-transform: uppercase;
    }

    .div_serv {
      display: flex;
      flex-wrap: wrap;
      gap: 2.4rem;
    }

    .div_serv_el {
      display: flex;
      gap: 0.8rem;
      align-items: center;
    }

    .div_serv_text {
      font-weight: 500;
      letter-spacing: 0.02em;
    }

    .div_img {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3.2rem;
      }
    }

    @media (max-width: 991px) {
      .div_info {
        flex-direction: column;
      }
      .div_info_left {
        width: auto;
      }

      .div_info_right {
        width: auto;
      }
    }
  }
}
</style>