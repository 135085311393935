<template>
  <container v-if="dataInfo && Object.keys(dataInfo).length !== 0" class="container3">
    <div class="movieThemes">
      <h3 class="div_tit" v-if="dataInfo.title">
        {{ dataInfo.title.value }}
      </h3>
      <div class="div_desc" v-if="dataInfo.description" v-html="dataInfo.description.value"></div>
      <div class="items">
        <div v-for="(item, key) of dataInfo.items" :key="key">
          <div v-if="item.active" class="card_movieTheme">
            <row>
              <column lg="6">
                <img class="img" :src="getImage(item.image)" alt="">
              </column>
              <column lg="6">
                <cl-collapse class="collapse_MT" v-if="item.titleText || item.text || item.link">
                  <template v-slot:name>
                    <div class="dt1">
                      <div class="tit" v-if="item.title">{{ item.title.value }}</div>
                      <div class="desc" v-if="item.description" v-html="item.description.value"></div>
                    </div>
                    <div class="dt2"></div>
                  </template>
                  <template v-slot:card>
                    <div class="example" v-if="item.titleText">{{ item.titleText.value }}</div>
                    <div class="div_text" v-if="item.text" v-html="item.text.value"></div>
                    <a class="link" :href="item.link.value.src" v-if="item.link && item.linkText" download>{{ item.linkText.value }}</a>
                  </template>
                </cl-collapse>
                <template v-else>
                  <div class="tit" v-if="item.title">{{ item.title.value }}</div>
                  <div class="desc" v-if="item.description" v-html="item.description.value"></div>
                </template>
              </column>
            </row>
          </div>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import Container from "@/components/library/Container.vue";
import Column from "@/components/library/Column.vue";
import ClCollapse from "@/components/library/ClCollapse.vue";

export default {
  components: {ClCollapse, Column, Container},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
};
</script>

<style lang="scss" scoped>
  .movieThemes {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12rem 0;
    border-radius: 3.2rem;
    background-color: #0C0C0E;
    .div_tit {
      margin-bottom: 1.6rem;
      text-transform: uppercase;
    }
    .div_desc {
      max-width: 58.8rem;
      text-align: center;
      color: #6B7280;
      letter-spacing: 0.018rem;
    }
    .card_movieTheme {
      padding: 3.2rem 12rem;
      &:not(:last-child){
        border-bottom: 1px solid #2E3138;
      }
      .img {
        width: 38.4rem;
        height: 21.6rem;
        object-fit: cover;
        border-radius: 1.6rem;
        filter: grayscale(100%);
        transition: all 250ms ease;
      }
      &:hover {
        .img {
          filter: grayscale(0);
        }
      }
      .tit {
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 133.33%;
        margin-bottom: 0.8rem;
      }
      .desc {
        font-size: 1.6rem;
        line-height: 150%;
        color: #6B7280;
      }
      .example {
        font-weight: 500;
        text-transform: uppercase;
        color: #6B7280;
      }
      .div_text {
        font-size: 1.6rem;
        line-height: 150%;
        margin-bottom: 0.8rem;
      }
      .link {
        display: inline-block;
        padding: 1.2rem 0;
        font-size: 1.6rem;
        line-height: 150%;
        color: #ED850A;
      }
      :deep(.collapse_text){
        width: 100%;
      }
    }
    @media (max-width: 991px) {
      padding: 6rem 2.4rem;
      .div_tit {
        font-size: 3.2rem;
        line-height: 150%;
        font-weight: 500;
      }
      .card_movieTheme {
        padding: 3.2rem 0;
        .img {
          width: 100%;
        }
        .tit {
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 133.33%;
          max-width: calc(100% - 72px);
          min-height: 4rem;
          margin-bottom: 1.6rem;
        }
        .row {
          gap: 1.6rem 0;
        }
      }
    }
  }
</style>