<template>
  <div class="list_movie list_movie_card" v-bind="$attrs" v-if="dataInfo.list && dataInfo.list.length > 0">
    <div class="sub_block_black_wrapper">
      <div class="sub_block_black">
        <container>
          <template v-for="galCat in dataInfo.list">
            <div class="gal_item" v-if="galCat.film?.films?.length > 0">
              <h3 v-if="galCat.title">{{ galCat.title.value }}</h3>
              <swiper
                  :spaceBetween="24"
                  :navigation="true"
                  :modules="modules"
                  :slidesPerView="'auto'"
                  class="progress-bar-line"
                  :pagination="{
            type: 'progressbar',
          }"
              >
                <swiper-slide
                    :key='itemFilm'
                    v-for='(itemFilm, index) in galCat.film?.films'
                >
                  <card card-type="film">
                    <div class="div_img div_img_prop">
                      <div class="div_img_wrapp">
                        <img :src="itemFilm.banner" alt="" loading="lazy">
                      </div>
                      <div class="div_platform_wrap" v-if="itemFilm.link?.length > 0">
                        <!--                    <template v-for="linkItem in itemFilm.link">-->
                        <!--                      <a v-if="linkItem.link && linkItem.platform?.url" class="div_platform" :href="linkItem.link" target="_blank">-->
                        <!--                        <img :src="linkItem.platform.url" alt="">-->
                        <!--                      </a>-->
                        <!--                      <div v-else-if="linkItem.platform?.url" class="div_platform">-->
                        <!--                          <img :src="linkItem.platform.url" alt="">-->
                        <!--                      </div>-->
                        <!--                    </template>-->
                        <!--                    <div class="dt">где посмотреть</div>-->
                        <div @click="showModalLink(itemFilm.link)" class="btnShowModal">
                          <span class="div_textButton" v-if="itemFilm.link.length > 1">Онлайн-кинотеатры</span>
                          <span class="div_textButton" v-if="itemFilm.link.length <= 1">Онлайн-кинотеатр</span>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.875 5H18.875V11" stroke="#ED850A" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M18.875 5L4.875 19" stroke="#ED850A" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                          </svg>
                        </div>
                      </div>
                      <div class="div_new" v-if="itemFilm.cinemaNow">Сейчас в кино</div>
                    </div>
                    <div class="div_title fs4" v-if="itemFilm.name">{{ itemFilm.name }}</div>
                  </card>
                </swiper-slide>
              </swiper>
            </div>
          </template>
        </container>
      </div>
    </div>

  </div>
  <cl-modal v-model="modalCinemaAll" modal-type="center" class="modalCinemaLink modal-scroll">
    <template v-slot:header>
      <div class="text-center titleModal">Выберите онлайн платформу <br> для просмотра кино</div>
    </template>
    <template v-slot:body>
      <template v-for="linkItem in modalCinemaItem">
        <a v-if="linkItem.link && linkItem.platform?.url" :href="linkItem.link" target="_blank"
           class="div_blockNavCinema">
          <div class="div_platform">
            <img :src="linkItem.platform.url" alt="">
          </div>
          <div class="div_text">{{ linkItem.platform.name }}</div>
        </a>
      </template>
    </template>
  </cl-modal>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import Card from "@/components/library/Card.vue";
import {mapGetters} from "vuex";

export default {
  name: "listMovieGalleries",
  components: {
    Card,
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      modalCinemaAll: false,
      modalCinemaItem: [],
    }
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ])
  },
  methods: {

    showModalLink(item) {
      this.modalCinemaItem = item;
      this.modalCinemaAll = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.modalCinemaLink {

  .titleModal {
    font-size: 3.2rem;
    text-transform: uppercase;
    max-width: 46.4rem;
    margin-bottom: 1.6rem;
    color: #ED850A;
  }

  .div_blockNavCinema {
    padding: .8rem 3.2rem;
    border-radius: .6rem;
    border: 1px solid #ED850A;
    display: flex;
    margin-bottom: 1.6rem;

    &:last-child {
      margin-bottom: 0;
    }

    .div_text {
      color: #FFFFFF;
      align-self: center;
      margin: 0 auto;
    }

    .div_platform {
      width: 48px;
      height: 48px;
      cursor: pointer;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  @media(max-width: 991px) {
    .titleModal {
      font-size: 3rem;
      line-height: 120%;
    }
  }
  @media(max-width: 767px) {
    .titleModal {
      margin-top: calc(30% - 3rem);
    }
  }
}

.btnShowModal {
  cursor: pointer;
  z-index: 1;
  width: 4.8rem;
  height: 4.8rem;
  padding: 1.2rem;
  border-radius: .6rem;
  background: black;
  display: flex;
  transition: .2s ease;
  justify-content: space-between;

  .div_textButton {
    overflow: hidden;
    transition: .1s ease;
    font-size: 1.8rem;
    align-self: center;
    opacity: 0;
    width: 0;
    white-space: nowrap;
  }
}

.list_movie {

  h3 {
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  .gal_item {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :deep(.card-film) {
    padding: 0;
    border-radius: 0;
    background: none;

    &:hover {
      .div_platform_wrap {
        color: rgba(255, 255, 255, 1);

        &:before {
          opacity: 1;
        }
      }

      .btnShowModal {
        padding: 1.2rem 1.2rem 1.2rem 2.4rem;
        width: 26rem;
        transition: .2s ease;

        .div_textButton {
          margin-right: .8rem;
          opacity: 1;
          width: fit-content;
          transition: .1s ease;
        }
      }
    }

    .div_img {
      border-radius: 8px;
      padding-top: 143%;
      margin-bottom: 8px;
      overflow: hidden;
      background-color: #000106;
      border: 1px solid #6B7280;
    }

    .div_platform_wrap {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 8px;
      transition: all .3s ease;
      color: rgba(255, 255, 255, 0);

      &:before {
        content: "";
        display: block;
        background: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: all .3s ease;
      }

      .dt {
        text-align: right;
        width: 100%;
        position: relative;
      }
    }

    .div_new {
      position: absolute;
      right: 14px;
      bottom: 12px;
      padding: 0 0 7px 0;
      width: 172px;
      height: 54px;
      font-weight: 300;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url(@/assets/img/now_cinema.svg) no-repeat center top;
      background-size: contain;
    }

  }
}

.list_movie_card {

  .sub_block_black{
    overflow: hidden;
  }

  .container{
    background: #0C0C0E;

    h3{
      text-align: center;
      margin-bottom: 3.2rem;
    }

    .swiper{
      overflow: visible;
    }

    .swiper-slide{
      width: 37rem;
      max-width: 37rem;

      @media (max-width: 767px){
        width: 25rem;
        max-width: 25rem;
      }
    }

    @media (max-width: 991px){
      h3{
        font-size: 3.2rem;
      }
    }

    @media (max-width: 767px){
      padding: 1.6rem;
      h3{
        font-size: 2.4rem;
        margin-bottom: 1.2rem;
      }
    }
  }
}
</style>