import { createStore } from 'vuex';
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

function getData(url, params, variable, context) {
  return axios
      .get(url, { params: params})
      .then(response => {
        context.commit(variable, response.data)
      })
      .catch(error => {
        notify({
          type: 'error',
          title: `Ошибка ${error ? error.response?.status : ''}`,
          text: `${error.response?.status + "\n" + (error.response.errorMessage ? error.response.errorMessage : 'Извините, что-то пошло не так')}`,
          speed: 500,
          duration: 5000,
        });
      });
}
export default createStore({
  actions: {
    fetchUser(context) {
      return getData('/api/get/user', '', 'updateUser', context)
    },
    fetchPersonData(context) {
      return getData('/api/get/physical-persons', '', 'updatePersonData', context)
    },
    widthSite(context){
      context.commit('updateWidthSite', window.innerWidth)
    },
  },
  mutations: {
    updateWidthSite(state, data){
      state.widthSite = data;
    },
    updateCategoryPeople(state, data){
      state.categoryPeople = data;
    },
    updateShoppingCart(state, data){
      state.shoppingCart = data;
    },
    updateUser(state, data) {
      state.user = data;
    },
    updatePersonData(state, data) {
      state.personData = data;
    },
  },
  state: {
    user: null,
    personData: null,
    widthSite: window.innerWidth,
    categoryPeople: 'tabCategoryPeople0',
    shoppingCart: localStorage.getItem('shoppingCart') ? JSON.parse(localStorage.getItem('shoppingCart')) : []
  },
  getters: {
    getIsAuthorization(state) {
      return !!state.user;
    },
    getIsCompany(state) {
      return !!state?.user?.currentCompany;
    },
    getCompany(state) {
      return state?.user?.currentCompany;
    },
    getUser(state) {
      return state.user;
    },
    getPersonData(state) {
      return state.personData;
    },
    widthSite(state){
      return state.widthSite
    },
    shoppingCart(state){
      return state.shoppingCart
    },
    getCategoryPeople(state){
      return state.categoryPeople
    },
  },
  modules: {
  }
})
