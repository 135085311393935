<template>

  <template v-if="!errorUrl">
    <template v-if="data && data.length > 0">


      <component :is="layoutData">


        <template v-for="area in data">
          <template v-if="area[0].blocks && area[0].blocks.length > 0">
            <template v-for="block in area[0].blocks">
              <header-login v-if="block.component == 'headerLogin'" :dataInfo="block.blockVariables"/>
              <breadcrumbs v-else-if="block.component == 'breadcrumbs' || block.component == 'breadcrumbs1' || block.component == 'breadcrumbs2' || block.component == 'breadcrumbs3' || block.component == 'breadcrumbs4'" :type="block.component" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <film-theater-rental v-else-if="block.component == 'bannerRent'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <map-block v-else-if="block.component == 'pavilionsOnMap'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <tabs-big-fon-img v-else-if="block.component == 'comingOnPlatform'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <subscribe-email v-else-if="block.component == 'mailing'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor" class="block_wrap"/>
              <our-partners v-else-if="block.component == 'partners' || block.component == 'withSupport'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"
                            :class="{'with_support': block.component == 'withSupport'}"
              />
              <collapse-list v-else-if="block.component == 'questions'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <collapse-list v-else-if="block.component == 'questionsWh'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"
                             class="block_wh"
              />
              <banner-title-first v-else-if="block.component == 'bannerTitleFirst'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <questions-suggestions v-else-if="block.component == 'feedback'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <rent-choice v-else-if="block.component == 'bannerCatalog'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <smi-about-us v-else-if="block.component == 'smiAboutUs'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <smi-about-us v-else-if="block.component == 'smiAboutUsBlack'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor" class="smi_black"/>
              <Footer v-else-if="block.component == 'footer'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor" />
              <footer-form v-else-if="block.component == 'footerForm'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <footer-form-england v-else-if="block.component == 'footerFormEngland'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <main-day-night v-else-if="block.component == 'bannerMain'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor" />
              <main-day-night-en v-else-if="block.component == 'bannerMainEn'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor" />
              <accordion-vertical v-else-if="block.component == 'suitable'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor" />
              <famous-people v-else-if="block.component == 'listSlide'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <survey-main v-else-if="block.component == 'surveyMain'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <coming-on-platform v-else-if="block.component == 'comingOnPlatformMain'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <reviews v-else-if="block.component == 'reviews'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <banner-recv v-else-if="block.component == 'bannerRecv'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <tour-video v-else-if="block.component == 'tour3d'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <rent-requisites-list v-else-if="block.component == 'bannerRequisites'" :dataInfo="block.blockVariables" :dataFilters="filterTop" @isComponent="getIsComponent" @getCategory="getTechCategory" :id="block.blockVariables?.settings?.anchor"/>
              <lang-top v-else-if="block.component == 'langTop'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <header-menu v-else-if="block.component == 'headerMenu'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <list-cards-develop v-else-if="block.component == 'listCardsDevelop'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <infrastructure-metmash v-else-if="block.component == 'infrastructureMetmash'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <full-cycle-services v-else-if="block.component == 'fullCycleServices'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <full-new-shema-services v-else-if="block.component == 'fullNewShemaServices'" :dataInfo="block.blockVariables"  :id="block.blockVariables?.settings?.anchor"/>
              <cart-page v-else-if="block.component == 'cartPage'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <menu-global v-else-if="block.component == 'menuGlobal'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <cart-top v-else-if="block.component == 'cartTop'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <moschino-services v-else-if="block.component == 'moschinoServices'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <moskino-cinema-park v-else-if="block.component == 'moskinoCinemaPark'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <rent-oborudovanie v-else-if="block.component == 'rentOborudovanie'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <rent-oborudovanie v-else-if="block.component == 'rentOborudovanieRedisign'" class="block_topCinemaGrant" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <banner-cards v-else-if="block.component == 'bannerCards'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <film-schools-list v-else-if="block.component == 'filmSchoolsList'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <film-schools-item v-else-if="block.component == 'filmSchoolsItem'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <transport-list v-else-if="block.component == 'transportList'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <transport-categories v-else-if="block.component == 'transportCategories'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <transport-item v-else-if="block.component == 'transportItem'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <equipment-list v-else-if="block.component == 'equipmentList'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <equipment-categories v-else-if="block.component == 'equipmentCategories'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <equipment-item v-else-if="block.component == 'equipmentItem'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <film-grants-list v-else-if="block.component == 'filmGrantsList'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <full-page-carousel v-else-if="block.component == 'fullPageCarousel'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <full-page-carousel v-else-if="block.component == 'fullPageCarouselBL'" :dataInfo="block.blockVariables" class="bottonLeft" :numeric="true" :id="block.blockVariables?.settings?.anchor"/>
              <grid-block v-else-if="block.component == 'gridBlock'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <placement-issue v-else-if="block.component == 'placementIssue'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <placement-issue-no-speaker v-else-if="block.component == 'placementIssueNoSpeaker'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <pavilony-list-types v-else-if="block.component == 'pavilonyListTypes'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <gorky-schemes-services v-else-if="block.component == 'gorkySchemesServices'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <list-movie-galleries v-else-if="block.component == 'listMovieGalleries'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <block-button-kinokomis v-else-if="block.component == 'blockButtonKinokomis'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <gallerie-description-and-information-block v-else-if="block.component == 'gallerieDescriptionAndInformationBlock'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <arenda-recvizita-top-filter @searchFun="getDataRecv" v-else-if="block.component == 'menuRevcvizit'" :techCategory="temp1" :isFilter="temp2" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <title-image v-else-if="block.component == 'titleImage'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <list-movie-galleries-england v-else-if="block.component == 'listMovieGalleriesEngland'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <tabs-block-england v-else-if="block.component == 'tabsBlockEngland'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <video-rutube v-else-if="block.component == 'videoRutube'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <video-rutube2 v-else-if="block.component == 'videoRutube2'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <gallary-carusel-en v-else-if="block.component == 'GallaryCaruselEn'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <tabs-block-england v-else-if="block.component == 'tabsEng'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <banner-mos-sсhool v-else-if="block.component == 'bannerMosSchool'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <film-school-advantages v-else-if="block.component == 'filmSchoolAdvantages'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <voting-film-school v-else-if="block.component == 'votingFilmSchool'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <list-training-programs v-else-if="block.component == 'listTrainingPrograms'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <test-form v-else-if="block.component == 'testForm'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <quiz v-else-if="block.component == 'quiz'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <blog-list v-else-if="block.component == 'blogList'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <partners-arenda v-else-if="block.component == 'partnersArend'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <tech-work v-else-if="block.component == 'techWork'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <lk-menu v-else-if="block.component == 'lkMenu'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <profile v-else-if="block.component == 'profile'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <my-applications v-else-if="block.component == 'MyApplications'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <my-applications-action v-else-if="block.component == 'MyApplicationsAction'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <trusted-persons v-else-if="block.component == 'trustedPersons'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <film-projects v-else-if="block.component == 'filmProjects'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <button-footer v-else-if="block.component == 'buttonFileFooter'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <selected-blog v-else-if="block.component == 'selectedBlog'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <map-arenda-list v-else-if="block.component == 'mapBlock'" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>

              <!--      Все новые компоненты блоков тперь добавлять в специально отведённые компоненты        -->
              <!--      templateDynamic1.vue - Павлов Илья        -->
              <template-dynamic1 :blockComponent="block.component" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <!--      templateDynamic2.vue - Ганин Дмитрий        -->
              <template-dynamic2 :blockComponent="block.component" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <!--      templateDynamic3.vue - Аверьянов Александр        -->
              <template-dynamic3 :blockComponent="block.component" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <!--      templateDynamic3.vue - Лисицын Ярослав        -->
              <template-dynamic4 :blockComponent="block.component" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <!--      templateDynamic5.vue - Вихров Никита        -->
              <template-dynamic5 :blockComponent="block.component" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
              <!--      templateDynamic6.vue - Новоселов Илья       -->
              <template-dynamic6 :blockComponent="block.component" :dataInfo="block.blockVariables" :id="block.blockVariables?.settings?.anchor"/>
            </template>

          </template>
        </template>

      </component>

    </template>
    <div v-else>
      <div class="loader_site">
        <loader/>
      </div>
    </div>
  </template>
  <template v-else>

    <not-found :errorUrlType="errorUrlType"/>

  </template>


</template>

<script>
//шаблоны
import Default from "@/layouts/Default.vue";
import FonGrey from "@/layouts/FonGrey.vue";
import ContainerWidth1200 from "@/layouts/ContainerWidth1200.vue";



// блоки
import textOne from "@/components/TextOne.vue";
import NotFound from "@/views/notFound.vue";
import FilmTheaterRental from "@/components/film_theater_rental.vue";
import MapBlock from "@/components/mapBlock.vue";
import TabsBigFonImg from "@/components/tabsBigFonImg.vue";
import SubscribeEmail from "@/components/subscribeEmail.vue";
import OurPartners from "@/components/ourPartners.vue";
import CollapseList from "@/components/collapseList.vue";
import smiAboutUs from "@/components/smiAboutUs.vue";
import QuestionsSuggestions from "@/components/questionsSuggestions.vue";
import RentChoice from "@/views/rentChoice.vue";
import Footer from "@/components/footer.vue";
import FooterForm from "@/components/footerForm.vue";
import MainDayNight from "@/components/mainDayNight.vue";
import AccordionVertical from "@/components/accordionVertical.vue";
import FamousPeople from "@/components/famousPeople.vue";
import ComingOnPlatform from "@/components/comingOnPlatform.vue";
import Reviews from "@/components/reviews.vue";
import BannerRecv from "@/components/bannerRecv.vue";
import TourVideo from "@/components/tourVideo.vue";
import LangTop from "@/components/langTop.vue";
import HeaderMenu from "@/components/headerMenu.vue";
import {nextTick} from "vue";
import CartPage from "@/components/cartPage.vue";
import BannerTitleFirst from "@/components/bannerTitleFirst.vue";
import ListCardsDevelop from "@/components/listCardsDevelop.vue";
import InfrastructureMetmash from "@/components/infrastructureMetmash.vue";
import FullCycleServices from "@/components/fullCycleServices.vue";
import SurveyMain from "@/components/surveyMain.vue";
import MenuGlobal from "@/components/menuGlobal.vue";
import CartTop from "@/components/cartTop.vue";
import MoschinoServices from "@/components/moschinoServices.vue";
import MoskinoCinemaPark from "@/components/moskinoCinemaPark.vue";
import RentOborudovanie from "@/components/rentOborudovanie.vue";
import BannerCards from "@/components/bannerCards.vue";
import FilmSchoolsList from "@/components/filmSchoolsList.vue";
import FilmSchoolsItem from "@/components/filmSchoolsItem.vue";
import TransportList from "@/components/transportList.vue";
import TransportCategories from "@/components/transportCategories.vue";
import TransportItem from "@/components/transportItem.vue";
import EquipmentList from "@/components/equipmentList.vue";
import EquipmentCategories from "@/components/equipmentCategories.vue";
import EquipmentItem from "@/components/equipmentItem.vue";
import FilmGrantsList from "@/components/filmGrantsList.vue";
import FullPageCarousel from "@/components/fullPageCarousel.vue";
import GridBlock from "@/components/gridBlock.vue";
import PlacementIssue from "@/components/placementIssue.vue";
import PlacementIssueNoSpeaker from "@/components/placementIssueNoSpeaker.vue";
import HeaderLogin from "@/components/headerLogin.vue";
import LkMenu from "@/components/lkMenu.vue";
import profile from "@/views/personal/profile.vue";
import MyApplications from "@/views/personal/MyApplications.vue";
import MyApplicationsAction from "@/views/personal/MyApplicationsAction.vue";
import PavilonyListTypes from "@/components/pavilonyListTypes.vue";
import GorkySchemesServices from "@/components/gorkySchemesServices.vue";
import ListMovieGalleries from "@/components/listMovieGalleries.vue";
import listMovieGalleriesEngland from "@/components/listMovieGalleriesEngland.vue";
import GallerieDescriptionAndInformationBlock from "@/components/gallerieDescriptionAndInformationBlock.vue";
import ArendaRecvizitaTopFilter from "@/components/arendaRecvizitaTopFilter.vue";
import BlockButtonKinokomis from "@/components/blockButtonKinokomis.vue";
import TitleImage from "@/components/titleImage.vue";
import TrustedPersons from "@/components/trustedPersons.vue";
import BannerMosSсhool from "@/components/bannerMosSchool.vue";
import FilmSchoolAdvantages from "@/components/filmSchoolAdvantages.vue";
import VotingFilmSchool from "@/components/votingFilmSchool.vue";
import ButtonFooter from "@/components/buttonFooter.vue";
import MainDayNightEn from "@/components/mainDayNightEn.vue";
import tabsBlockEngland from "@/components/tabsBlockEngland.vue";
import VideoRutube from "@/components/videoRutube.vue";
import GallaryCaruselEn from "@/components/GallaryCaruselEn.vue";
import footerFormEngland from "@/components/footerFormEngland";
import ListTrainingPrograms from "@/components/listTrainingPrograms.vue";
import filmProjects from "@/components/filmProjects.vue";
import TestForm from "@/components/testForm.vue";
import Quiz from "@/components/quiz.vue";
import PartnersArenda from "@/components/partnersArend.vue";
import FullNewShemaServices from "@/components/fullNewShemaServices.vue";
import blogList from "@/components/blogList.vue";
import TechWork from "@/components/techWork.vue";
import selectedBlog from "@/components/selectedBlog.vue";
import TemplateDynamic1 from "@/views/templateDynamic1.vue";
import TemplateDynamic2 from "@/views/templateDynamic2.vue";
import TemplateDynamic3 from "@/views/templateDynamic3.vue";
import TemplateDynamic4 from "@/views/templateDynamic4.vue";
import TemplateDynamic5 from "@/views/templateDynamic5.vue";
import TemplateDynamic6 from "@/views/templateDynamic6.vue";
import MapArendaList from "@/components/mapArendaList.vue";
import RentRequisitesList from "@/components/rentRequisitesList.vue";
import VideoRutube2 from "@/components/videoRutube2.vue";

export default {
  name: "dynamicPageAction",
  components: {
    VideoRutube2,
    RentRequisitesList,
    MapArendaList,
    TemplateDynamic1,
    TemplateDynamic2,
    TemplateDynamic3,
    TemplateDynamic4,
    TemplateDynamic5,
    TemplateDynamic6,
    Quiz,
    FullNewShemaServices,
    TechWork,
    ListTrainingPrograms,
    VotingFilmSchool,
    FilmSchoolAdvantages,
    BannerMosSсhool,
		TestForm,
    ButtonFooter,
    footerFormEngland,
    GallaryCaruselEn,
    VideoRutube,
    MainDayNightEn,
    tabsBlockEngland,
    TitleImage,
    TrustedPersons,
    BlockButtonKinokomis,
    ArendaRecvizitaTopFilter,
    GallerieDescriptionAndInformationBlock,
    ListMovieGalleries,
    listMovieGalleriesEngland,
    GorkySchemesServices,
    PavilonyListTypes,
    MyApplicationsAction,
    MyApplications,
    profile,
    LkMenu,
    HeaderLogin,
    PlacementIssue,
    PlacementIssueNoSpeaker,
    GridBlock,
    FullPageCarousel,
    FilmGrantsList,
    EquipmentItem,
    EquipmentCategories,
    EquipmentList,
    TransportItem,
    TransportCategories,
    TransportList,
    FilmSchoolsItem,
    FilmSchoolsList,
    BannerCards,
    RentOborudovanie,
    MoskinoCinemaPark,
    MoschinoServices,
    CartTop,
    MenuGlobal,
    SurveyMain,
    FullCycleServices,
    InfrastructureMetmash,
    ListCardsDevelop,
    BannerTitleFirst,
    CartPage,
    HeaderMenu,
    LangTop,
    TourVideo,
    BannerRecv,
    Reviews,
    ComingOnPlatform,
    FamousPeople,
    AccordionVertical,
    MainDayNight,
    FooterForm, Footer,
    RentChoice,
    QuestionsSuggestions,
    CollapseList, OurPartners, SubscribeEmail, TabsBigFonImg, MapBlock, FilmTheaterRental, NotFound, textOne, smiAboutUs,
	  filmProjects,
    blogList,
    selectedBlog,
    PartnersArenda,
  },
  data() {
    return {
      data: null,
      dataMeta: null,
      isLoading: true,
      controller: null,
      errorUrl: false,
      errorUrlType: 404,
      headerLogoContent: false,
      startMeta: false,
      /**
       * Состояние добавленных метрик "Top.Mail.Ru" на страницу.
       * @type {boolean}
       * @default false
       */
      startMetaTopMailRu: false,
      dataTitle: null,
      // Переменная используется для связывания шаблонов (Общая)
      filterTop: null,
      temp1: null,
      temp2: null,
      layoutData: Default,
    }
  },
  methods: {
    getData(id) {
      this.axios
          .get(`/api/page${id}`, {signal: this.controller.signal})
          .then(response => {
            this.errorUrl = false;


            //меняем шаблон
            if(response.data?.layout){
              if(response.data.layout == "Default") {
                this.layoutData = Default;
              }
              if(response.data.layout == "FonGrey") {
                this.layoutData = FonGrey;
              }
              if(response.data.layout == "ContainerWidth1200") {
                this.layoutData = ContainerWidth1200;
              }
            }

            this.data = response.data.hierarchy;
            //console.log(response.data)
            [...this.data].map(item => {
              if(item[0].blocks && item[0].blocks.length > 0){
                [...item[0].blocks].map(itemBlock => {
                  if(itemBlock.component == 'logoTop' && (!this.headerLogoContent || (this.headerLogoContent?.name?.value != itemBlock.blockVariables?.name?.value || this.headerLogoContent?.src?.value?.src != itemBlock.blockVariables?.src?.value?.src))){
                    this.headerLogoContent = itemBlock.blockVariables;
                    this.$emit("updateHeaderLogo", itemBlock.blockVariables);
                  }
                  if(itemBlock.component == 'globalAlertData' && itemBlock.blockVariables && Object.keys(itemBlock.blockVariables).length !== 0){
                    this.$emit("addGlobalAlertData", itemBlock.blockVariables);
                  }
                })
              }
            })


            this.dataMeta = response.data.metaList;
            this.dataTitle = this.dataMeta?.title ? this.dataMeta.title : response.data.title;

            if (id == "/"){
              document.title = "Московская КиноПлатформа";
            } else {
              document.title = this.dataTitle + ' - Московская КиноПлатформа';
            }

            if(this.startMeta){
              /**
               * яндекс метрика при переходе по страницам
               */
              ym(94757334, 'hit', this.$route.fullPath, { params: {
                  title: this.dataTitle,
                }
              });
            } else {
              if(process.env.NODE_ENV === "production"){
                /**
                 * яндекс метрика при первом открытие сайта
                 */
                const element  = document.createElement("script");
                element.setAttribute("type", "text/javascript");
                element.innerHTML = "            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n" +
                  "                m[i].l=1*new Date();\n" +
                  "                for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n" +
                  "                k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n" +
                  "            (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n" +
                  "\n" +
                  "            ym(94757334, \"init\", {\n" +
                  "                clickmap:true,\n" +
                  "                trackLinks:true,\n" +
                  "                accurateTrackBounce:true,\n" +
                  "                webvisor:true\n" +
                  "            });\n";
                const element2 = document.createElement("noscript");

                element2.append('<div><img src="https://mc.yandex.ru/watch/94757334" style="position:absolute; left:-9999px;" alt="" /></div>');
                document.head.appendChild(element);
                document.head.appendChild(element2);

                /**
                 * выводим метрику из настроек админки сайта  в head
                 */
                if(response.data.site.headMetrics){
                  document.head.insertAdjacentHTML('beforeEnd', response.data.site.headMetrics);
                }

                this.startMeta = true;
              }
            }
            //console.log(this.data)

            if(this.startMetaTopMailRu) {
              var _tmr = window._tmr || (window._tmr = []);
              _tmr.push({ id: '3562693', type: 'pageView', url: this.$route.fullPath, start: (new Date()).getTime(), title: this.dataTitle});
            } else {
              if(process.env.NODE_ENV === "production") {
                const element  = document.createElement("script");
                element.setAttribute("type", "text/javascript");
                element.innerHTML = "var _tmr = window._tmr || (window._tmr = []);\n" +
                    "_tmr.push({id: '3562693', type: 'pageView', start: (new Date()).getTime()});\n" +
                    "(function (d, w, id) {\n" +
                    "if (d.getElementById(id)) return;\n" +
                    "var ts = d.createElement('script'); ts.type = 'text/javascript'; ts.async = true; ts.id = id;\n" +
                    "ts.src = 'https://top-fwz1.mail.ru/js/code.js';\n" +
                    "var f = function () {var s = d.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ts, s);};\n" +
                    "if (w.opera == '[object Opera]') { d.addEventListener('DOMContentLoaded', f, false); } else { f(); }\n" +
                    "})(document, window, 'tmr-code');\n"
                const element2 = document.createElement("noscript");
                element2.append('<div><img src="https://top-fwz1.mail.ru/counter?id=3562693;js=na" style="position:absolute; left:-9999px;" alt="Top.Mail.Ru" /></div>');
                document.head.appendChild(element);
                document.head.appendChild(element2);
                this.startMetaTopMailRu = true;
              }
            }
          })
          .then(() => {
              if(window.location.hash && document.querySelector(window.location.hash)){
                  document.querySelector(window.location.hash).scrollIntoView();
              }
          })
          .catch(error => {
            //this.showError(error);
            if(error?.response?.status == '403'){
              document.getElementById('modalAuthShow').classList.remove('d-none');
              document.getElementById('modalAuthHref').onclick = function() {
                history.pushState(null, null, window.location.href);
                window.location.href = '/connect/mosru';
              };
            }
            this.errorUrlType = error?.response?.status;
            this.errorUrl = true;
          });
    },
    getDataRecv(data){
      this.filterTop = data;
    },
    getTechCategory(data){
      this.temp1 = data;
    },
    getIsComponent(data){
      this.temp2 = data;
    },
  },
  watch: {
    $route() {
      // console.log(this.$router.options.history.state.back.replace(this.$route.hash, "") != this.$router.options.history.state.current.replace(this.$route.hash, ""))
      // console.log(this.$router.options.history.state.back.split('#')[0])
      // console.log(this.$router.options.history.state.current.split('#')[0])
      // console.log(this.$router.options.history.state.current.replace(this.$route.hash, ""))
      // если переход по якорю на той же странице не обновлять данные
      if(this.$route.hash == "" || (this.$route.hash != "" && this.$router.options.history.state.back && this.$router.options.history.state.back.split('#')[0] != this.$router.options.history.state.current.replace(this.$route.hash, ""))){
        this.data = false;
        this.getData(this.$route.path);

        setTimeout(() => {
            if(this.$route.hash != ""){
                this.$router.push({hash: this.$route.hash})
            }
        },500)
      }
    },
  },
  mounted() {
    this.getData(this.$route.path);
  },
  created() {
    this.controller = new AbortController();
  },
  beforeUnmount() {
    // отмена запросов
    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style lang="scss" scoped>
.loader_site {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>