<template>
  <div class="sub_block_black_wrapper">
    <div class="sub_block_black left_padding_mobile">
      <container>
        <div v-if="dataInfo && dataInfo.buttons && dataInfo.buttons.length > 0" class="kinopark_gallery">
          <div class="gallery_buttons">
            <div v-for="button of dataInfo.buttons" class="div_btn">
              <div v-if="button.buttonImg" class="gallery_button" @click="showModalGalFun(getGallery(button.images), 0)">
                <div class="div_bkgr_img" :style="getBkgrImage(button.buttonImg)">
                  <div class="div_gray_fon">
                    {{button.buttonText ? button.buttonText.value : ''}}
                  </div>
                </div>
              </div>
            </div>
            <modal-gallery :dataGallery="dataGallery" :initialSlide="initialSlide" :modelValue="showModalGal" :thumbsGal="true"/>
          </div>
        </div>
      </container>
    </div>
  </div>
</template>
<script>
import Column from "@/components/library/Column.vue";
import ClButton from "@/components/library/ClButton.vue";
import ModalGallery from "@/components/modalGallery.vue";

export default {
  name: "galleryButtons",
  components: {ModalGallery, ClButton, Column},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      dataGallery: false,
      initialSlide: 0,
      showModalGal: false
    }
  },
  methods: {
    showModalGalFun(dataGallery, initialSlide){
      this.dataGallery = dataGallery;
      this.initialSlide = initialSlide;
      this.showModalGal = true;
    },

    getGallery(photos) {
      return photos && photos.length > 0 ? photos.map((item) => {
        return item.image && item.image.value && item.image.value.src && item.image.value.src.fullHd && item.image.value.src.fullHd.url ? item.image.value.src.fullHd.url : null;
      }) : null;
    }
  },
}
</script>

<style lang="scss" scoped>
.gallery_buttons {
  background: rgba(12, 12, 14, 1);
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 2.4rem;

  @media (max-width: 767px) {
    overflow-x: scroll;
    justify-content: flex-start;
  }
}

.gallery_button {
  flex: none;
  width: 22rem;
  height: 22rem;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  border: 0.2rem solid transparent;
  background: linear-gradient(#fff, #fff 0) padding-box,
  linear-gradient(90deg, #F09819 0%, #FF512F 100%) border-box;

  .div_bkgr_img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .div_gray_fon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(23, 25, 28, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1.6rem;
  }

  &:hover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .div_gray_fon {
      background-color: rgba(23, 25, 28, 0);
    }
  }

  @media (max-width: 1439px){
      width: 15rem;
      height: 15rem;

    .div_gray_fon{
      font-size: 1.4rem;
    }
  }

  @media (max-width: 991px){
    width: 11.4rem;
    height: 11.4rem;
  }
}

.left_padding_mobile{
  @media (max-width: 767px){
    overflow: hidden;
    .container{
      padding-right: 0;
    }
  }
}

</style>